import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FilesPermissionEnum } from '@remberg/files/common/main';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store';

export const fileDriveGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const router = inject(Router);
  const logger = inject(LogService);
  const store = inject(Store);
  const hasFeature = await firstValueFrom(
    store.select(GlobalSelectors.selectHasFeature(FeatureFlagEnum.FILES)),
  );
  const hasPermission = await firstValueFrom(
    store.select(GlobalSelectors.selectHasPermission(FilesPermissionEnum.FILES_ENABLED)),
  );
  const isRestrictedToAssetDetails = await firstValueFrom(
    store.select(
      GlobalSelectors.selectHasPermission(FilesPermissionEnum.FILES_SHOW_ONLY_IN_ASSET_DETAILS),
    ),
  );
  if (hasFeature && hasPermission && !isRestrictedToAssetDetails) return true;

  logger.info()('File Drive Guard: Redirecting from: ' + router.url);
  void router.navigate(['/']);
  return false;
};
