<div
  id="rembergDialogWrapper"
  class="remberg-dialog-wrapper"
  [ngStyle]="dialogCustomStyles"
  [ngClass]="{ fullscreen: styleFullscreen }"
  [style.--height]="dialogCustomStyles?.heightVariable"
>
  <div
    class="remberg-dialog-top-margin"
    [ngClass]="{ 'remberg-dialog-top-margin-ios': styleFullscreen }"
  ></div>

  <div
    *ngIf="headerShow"
    class="remberg-dialog-header"
    [ngClass]="{
      sticky: styleStickyHeader,
      'remberg-dialog-header-ios': layout.isIos && (layout.isXSmallView | async),
    }"
  >
    <div class="dialog-title">
      <h2>{{ headerTitle }}</h2>
    </div>

    <div *ngIf="headerCloseActionShow" class="dialog-actions">
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </div>
  </div>

  <div
    class="remberg-dialog-margin-wrapper"
    [ngClass]="{
      'no-margin': styleNoMargin,
      'remove-top-margin': headerShow,
      'remove-bottom-margin': removeBottomMargin,
      'overflow-x-hidden': overflowXHidden,
      'overflow-y-hidden': overflowYHidden,
      'remberg-dialog-margin-wrapper-ios': layout.isIos && (layout.isXSmallView | async),
    }"
  >
    <div class="remberg-dialog-content-wrapper">
      <template #dialogContent></template>
    </div>
  </div>

  <div
    *ngIf="showButtons"
    style="padding: 20px"
    [fxLayout]="buttonsDirection === 'horizontal' ? 'row wrap' : 'column wrap'"
    [ngStyle]="buttonsDirection === 'horizontal' ? { 'justify-content': 'space-between' } : {}"
  >
    <ng-container *ngFor="let button of buttons">
      <button
        *ngIf="
          button.action === ButtonActions.CONFIRM ||
          (button.action === ButtonActions.ABORT && !hideAbortButton)
        "
        mat-raised-button
        [disabled]="
          button.action === ButtonActions.CONFIRM &&
          componentRef?.instance?.disableParentConfirmButton
        "
        [ngStyle]="buttonsDirection === 'vertical' ? { 'margin-top': '10px' } : {}"
        [color]="button.color"
        (click)="buttonPressed(button)"
        attr.data-test-id="{{ button.dataTestId }}"
      >
        <ng-container>{{ button.text }}</ng-container>
      </button>
    </ng-container>
  </div>
</div>
