<mat-form-field appearance="outline" class="my-mat-form-field my-mat-form-field-outline">
  <mat-label>
    <span>{{ label }}</span>
    <span *ngIf="currentLanguage" class="user-language">
      ({{ currentLanguage | translateIsoLanguageCodesEnum }})
    </span>
  </mat-label>
  <input
    matInput
    type="text"
    (focusout)="focusOut.next()"
    [formControl]="formGroup.controls.singleLanguageLabel"
    [attr.data-test-id]="dataTestId"
  />
  <mat-error
    *ngIf="formGroup.controls.singleLanguageLabel?.errors?.['required']"
    i18n="@@fieldIsRequiredDot"
  >
    Field is required.
  </mat-error>
</mat-form-field>

<button
  *ngIf="!hideTranslationsButton"
  mat-icon-button
  class="translate-button"
  data-test-id="multi-language-translations-button"
  [matTooltip]="translations.editTranslations"
  [disabled]="formGroup.disabled"
  (click)="openTranslationsDialog()"
>
  <mat-icon>translate</mat-icon>
</button>
