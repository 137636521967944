import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ButtonDropdownButton } from '@remberg/global/ui';

/* eslint-disable no-console */
@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
})
export class ButtonDropdownComponent {
  /** buttonList: takes the functions and listings for actions & rendering */
  @Input() public buttonList: ButtonDropdownButton[] = [
    // First Button is main button
    {
      label: $localize`:@@defaultButton:Default Button`,
      clickFunction: (): void => console.log('default button'),
      menuContentBeforeSelect: '.example-css-selector',
    },
  ];

  /** additionalClass: additional classes for styling */
  @Input() public additionalClass?: string;

  /** additionalClassIsSmallView: additional classes for styling for responsive cases */
  @Input() public additionalClassIsSmallView?: string;

  /** loading: adds functionality of button loader */
  @Input() public loading: boolean = false;

  /** color: colorString */
  @Input() public color?: string;

  /** color: material color theme */
  @Input() public materialColor: ThemePalette = undefined;

  /** buttonStyle: material style */
  @Input() public buttonStyle: 'mat-raised-button' | 'mat-flat-button' | 'mat-stroked-button' | '' =
    'mat-raised-button';

  /** isButtonSizeLarge: makes the button higher by applying 10px vertical padding increases the width of the arrow button */
  @Input() public isButtonSizeLarge = false;

  /* make button full width */
  @Input() public isFullWidth = false;

  /* spinner size for button loader */
  @Input() public buttonLoaderSpinnerSize!: number;

  /** borderStyle: directly set border-style property as string */
  @Input() public borderStyle?: string;

  /** highlightAsRequired: sets the button in "warn" state */
  @Input() public highlightAsRequired = false;

  /** dataTestId:
   * Used only for the main button. If no data-test-id is defined, the button's name is used instead.
   * The button's name is always used for the child buttons.
   */
  @Input() public dataTestId?: string;
  @Input() public dataTestIdForDropDown?: string;

  /** disabled: disables button */
  @Input() public disabled: boolean = false;

  /** backdropClass: add class to the material angular backdrop of the menu */
  @Input() public backdropClass!: string;

  // Emits on every click the corresponding button
  @Output() public onButtonClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor() {
    // empty
  }

  clicked(index: number): void {
    const clickFunction = this.buttonList[index]?.clickFunction;
    if (clickFunction) {
      clickFunction();
      this.onButtonClicked.emit(this.buttonList[index]);
    }
  }

  protected get getAdditionalButtonStyles(): Record<string, string> | undefined {
    const style: Record<string, string> = {};

    if (this.isButtonSizeLarge) {
      style['width'] = '100%';
      style['paddingTop'] = '10px';
      style['paddingBottom'] = '10px';
    }

    if (this.borderStyle) {
      style['borderStyle'] = this.borderStyle;
    }

    return Object.keys(style).length ? style : undefined;
  }
}
