import { AsyncPipe, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewContainerRef } from '@angular/core';
import { MatChipListbox } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService, environment } from '@remberg/global/ui';
import { GlobalActions, LayoutService, RootGlobalState } from '@remberg/ui-core/core';
import * as Bowser from 'bowser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, MatIcon, MatChipListbox, RouterOutlet, AsyncPipe],
})
export class AppComponent implements AfterViewInit {
  protected browserInfoText?: string;
  protected browserInfoTextSmallView?: string;

  constructor(
    private logger: LogService,
    public viewContainerRef: ViewContainerRef,
    public elementRef: ElementRef,
    protected layout: LayoutService,
    private store: Store<RootGlobalState>,
  ) {
    // check browser type and version
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidInternetExplorer = browser.satisfies({
      'internet explorer': '>10',
    });

    if (environment.live) {
      this.logger.debug()('This is a live server environment.');
    }

    if (isValidInternetExplorer) {
      // eslint-disable-next-line max-len
      this.browserInfoText = $localize`:@@internetExplorerIsNotFullySupportedByRembergWeRecommendSwitching:Internet Explorer is not fully supported by remberg. We recommend switching to a newer browser.`;
      this.browserInfoTextSmallView = $localize`:@@internetExplorerIsNotFullySupported:Internet Explorer is not fully supported.`;
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.browserInfoText) {
        this.showBanner();
      }
    }, 0);
  }

  protected closeBrowserInfo(): void {
    this.browserInfoText = undefined;
    this.hideBanner();
  }

  private hideBanner(): void {
    this.store.dispatch(GlobalActions.browserBannerStatusUpdated({ isShown: false }));
  }

  private showBanner(): void {
    this.store.dispatch(GlobalActions.browserBannerStatusUpdated({ isShown: true }));
  }
}
