import { SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { LogService, SyncDataTypesEnum } from '@remberg/global/ui';
import { SQLiteObjectMock } from '../sqlite-mock/sqlite-object-mock';

/**
 * Generic function to add column if it's missing from a table.
 * @param db
 * @param logger
 * @param tableName
 * @param columnName
 * @param columnType
 */
export async function addColumnIfMissing(
  db: SQLiteObject | SQLiteObjectMock,
  logger: LogService,
  tableName: SyncDataTypesEnum,
  columnName: string,
  columnType: 'TEXT' | 'INTEGER' | 'DATETIME',
): Promise<void> {
  const tableColumns = await db.executeSql(`PRAGMA table_info(${tableName});`, []);

  for (let i = 0; i < tableColumns.rows.length; i++) {
    if (tableColumns.rows.item(i).name === columnName) {
      logger.debug()(`Column ${columnName} already exists in table ${tableName}, skipping...`);
      return;
    }
  }

  try {
    await db.executeSql(`ALTER TABLE ${tableName} ADD COLUMN ${columnName} ${columnType};`, []);
    logger.debug()(`Added ${columnName} column to ${tableName} table`);
  } catch (err) {
    logger.error()(`Error during adding ${tableName} table ${columnName} column.`, err);
  }
}
