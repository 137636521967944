import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { TicketsPermissionEnum } from '@remberg/tickets2/common/main';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store/global';

export const tickets2GuardFn: CanActivateFn = async (): Promise<boolean> => {
  const store = inject(Store);
  const router = inject(Router);
  const logger = inject(LogService);
  const toastr = inject(ToastrService);

  const hasAccess = await firstValueFrom(
    store.select(
      GlobalSelectors.selectHasAccess(
        [FeatureFlagEnum.TICKETS_TEMPORARY],
        [TicketsPermissionEnum.TICKETS_ENABLED],
      ),
    ),
  );

  if (hasAccess) return true;

  logger.info()('Tickets2 Guard: Redirecting from: ' + router.url);
  toastr.warning($localize`:@@noTickets2FeatureAccess:Tickets2 feature access is not granted.`);
  void router.navigate(['/']);
  return false;
};
