import { UserGroupBasic } from '../definitions';

export function mapToUserGroupBasic({
  _id,
  name,
  type,
  description,
}: UserGroupBasic): UserGroupBasic {
  return { _id, name, type, description };
}
