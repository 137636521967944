import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainRoutes, PublicRoutes } from '@remberg/global/ui';
import {
  AuthGuardService,
  InitializationGuard,
  NotFoundGuardService,
  forceRerenderResolver,
} from '@remberg/ui-core/core';
import {
  PageNotFoundComponent,
  UpdateRequiredComponent,
} from '@remberg/ui-core/utility-pages-shared';

const publicRoutes: Routes = [
  {
    path: PublicRoutes.ASSETS,
    loadChildren: () =>
      import('@remberg/assets/ui/assets-main-public').then((m) => m.AssetsPublicModule),
  },
  // TODO: supporting both servicecases and request for now
  // but should be single after public tickets routes are unified
  {
    path: PublicRoutes.REQUEST,
    loadChildren: () =>
      import('@remberg/tickets/ui/tickets-main-public').then((m) => m.TicketsPublicModule),
  },
  {
    path: PublicRoutes.TICKETS,
    loadChildren: () =>
      import('@remberg/tickets/ui/tickets-main-public').then((m) => m.TicketsPublicModule),
  },
  {
    path: '**',
    redirectTo: '/portal/login',
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: MainRoutes.WELCOME,
    pathMatch: 'full',
  },
  {
    path: MainRoutes.PUBLIC,
    children: publicRoutes,
  },
  {
    path: '',
    loadChildren: () => import('@remberg/ui-core/landing-shell').then((m) => m.LANDING_ROUTES),
  },
  {
    path: MainRoutes.PORTAL,
    loadChildren: () =>
      import('@remberg/ui-core/landing-shell').then((m) => m.LANDING_PORTAL_ROUTES),
  },
  {
    path: '',
    loadChildren: () =>
      import('@remberg/ui-core/navigation-shell').then((m) => m.NAVIGATION_SHELL_ROUTES),
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
  },
  // Utility Routes
  { path: MainRoutes.UPDATE_REQUIRED, component: UpdateRequiredComponent },
  { path: MainRoutes.NOT_FOUND, pathMatch: 'full', component: PageNotFoundComponent },
  // catch all remaining routes (logged in)
  {
    path: '**',
    canActivate: [NotFoundGuardService],
    component: PageNotFoundComponent,
    pathMatch: 'full',
  },
  // catch all remaining routes (not logged in)
  { path: '**', redirectTo: MainRoutes.LOGIN, pathMatch: 'full' },
];

// in order to perform mobile migrations before any route is loaded,
// we must have a parent rootRoute with a guard that delays further navigation until the
// migration is complete. It also takes care of the appState initialization
const rootRoute: Routes = [
  {
    path: '',
    canActivate: [InitializationGuard],
    children: routes,
    resolve: { forceRerender: forceRerenderResolver },
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(rootRoute, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
