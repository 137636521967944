import { inject } from '@angular/core';
import { CanActivateFn, GuardResult, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { WorkOrderPermissionsEnum } from '@remberg/work-orders/common/main';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store';

export const workOrders2GuardFn: CanActivateFn = async (): Promise<GuardResult> => {
  const router = inject(Router);
  const store = inject(Store);
  const logger = inject(LogService);

  const hasPermission = await firstValueFrom(
    store.select(GlobalSelectors.selectHasPermission(WorkOrderPermissionsEnum.WORK_ORDERS_ENABLED)),
  );

  if (hasPermission) return true;

  logger.info()('Work Orders 2 Guard: Redirecting from: ' + router.url);
  return router.parseUrl('/');
};
