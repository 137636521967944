import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AssetsPermissionEnum } from '@remberg/assets/common/main';
import { LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store';

export const assetsGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const router = inject(Router);
  const logger = inject(LogService);
  const store = inject(Store);
  const hasPermission = await firstValueFrom(
    store.select(GlobalSelectors.selectHasPermission(AssetsPermissionEnum.ASSETS_ENABLED)),
  );
  const isIonic = await firstValueFrom(store.select(GlobalSelectors.selectIsIonic));

  if (hasPermission && !isIonic) return true;

  logger.info()('Assets Guard: Redirecting from: ' + router.url);
  void router.navigate(['/']);
  return false;
};
