import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService, MaintenancePlanRightsEnum } from '@remberg/global/ui';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store';

export const maintenancePlansGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const router = inject(Router);
  const logger = inject(LogService);
  const store = inject(Store);
  const toastr = inject(ToastrService);

  const hasPermission = await firstValueFrom(
    store.select(
      GlobalSelectors.selectHasPermission(MaintenancePlanRightsEnum.MAINTENANCE_PLAN_ENABLED),
    ),
  );

  if (hasPermission) return true;

  logger.info()('Maintenance Plan Guard: Redirecting from: ' + router.url);
  toastr.warning(
    $localize`:@@noMaintenancePlanFeature:Account feature "Maintenance Plan" is not granted.`,
  );
  void router.navigate(['/']);
  return false;
};
