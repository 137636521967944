// TODO Rename: Should be renamed to FormInstanceFilterFieldEnum once the old enum is deprecated
// Old enum is in libs/forms/common/dtos/src/form-instances/form-instance-table-query.ts
export enum FormInstanceAdvancedFilterFieldEnum {
  ASSIGNEE = 'assignee',
  FORM_TEMPLATE = 'formTemplate',
  RELATED_ASSETS = 'relatedAssets',
  RELATED_ORGANIZATIONS = 'relatedOrganizations',
  RELATED_WORK_ORDER_2 = 'relatedWorkOrder2',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  FINALIZED_AT = 'finalizedAt',

  // Custom logic filters
  EMAILS = 'emails',
  INVOLVED_CONTACT = 'involvedContact',
  CREATED_BY = 'createdBy',
}
