import { Assert, FeatureFlagEnum, TwoWayCompareStringType } from '@remberg/global/common/core';
import { FormPermissions } from '@remberg/users/common/main';

// This needs to be kept in sync with 'FormRightsEnum' in the shared service!
export enum FormTemplatePermissionsEnum {
  FORMS_ENABLED = 'forms_enabled',
  FORMS_NEW_TYPE = 'forms_newType',
  FORMS_UPDATE_TYPE = 'forms_updateType',
  FORMS_DELETE_TYPE = 'forms_deleteType',
}

export enum FormInstancePermissionsEnum {
  FORMS_ENABLED = 'forms_enabled',
  FORMS_FILL = 'forms_fill',
  /** Allows user to create more than one instance of forms that are 'singleInstance' */
  FORMS_FILL_OVERWRITE = 'forms_fillOverwrite',
  FORMS_UPDATE = 'forms_update',
  FORMS_DELETE = 'forms_delete',
  FORMS_DELETE_OWN = 'forms_deleteOwn',
  FORMS_SEND = 'forms_send',
  FORMS_UPDATE_ASSIGNEE = 'forms_updateAssignee',
  FORMS_VIEW_ALL = 'forms_viewAll',
  FORMS_DUPLICATE_ALL = 'forms_duplicateAllForms',
  FORMS_DUPLICATE_OWN = 'forms_duplicateOwnForms',

  FORMS_NOTES_CREATE_UPDATE_DELETE_OWN = 'forms_notesCreateUpdateDeleteOwn',
  FORMS_NOTES_DELETE_ALL = 'forms_notesDeleteAll',
}

export type AssertMatchingInterfaces = Assert<
  TwoWayCompareStringType<
    `${FeatureFlagEnum.FORMS}_${keyof FormPermissions}`,
    `${FormTemplatePermissionsEnum}` | `${FormInstancePermissionsEnum}`
  >
>;
