<form [formGroup]="refreshFormGroup" fxLayout="column">
  <div class="mat-body" i18n="@@yourSessionHasExpiredPleaseReenterYourCredentialsToProceed">
    Your session has expired. Please re-enter your credentials to proceed.
  </div>

  <mat-form-field
    class="my-mat-form-field my-mat-form-field-full-width top-margin"
    appearance="outline"
  >
    <mat-label i18n="@@email">Email</mat-label>
    <input matInput [value]="email" [disabled]="true" type="text" name="email" />
  </mat-form-field>

  <mat-form-field
    class="my-mat-form-field my-mat-form-field-full-width"
    [ngStyle]="twoFactorCheck ? { color: 'rgba(0,0,0,.38);' } : {}"
    appearance="outline"
  >
    <mat-label i18n="@@password">Password</mat-label>
    <input
      #passwordInput
      matInput
      formControlName="password"
      [readOnly]="twoFactorCheck"
      type="password"
      name="password"
      (change)="passwordNativeChange(passwordInput.value)"
    />
    <mat-error
      *ngIf="refreshFormGroup?.get('password')?.errors?.['incorrectCredentials']"
      i18n="@@credentialsAreIncorrectPleaseCheckYourEmailAndPasswordAgain"
      >Credentials are incorrect. Please check your email and password again.</mat-error
    >
  </mat-form-field>

  <mat-form-field
    class="my-mat-form-field my-mat-form-field-full-width"
    [ngStyle]="!twoFactorCheck ? { display: 'none' } : {}"
    appearance="outline"
  >
    <input matInput formControlName="twoFactorToken" type="password" name="twoFactor" />
  </mat-form-field>

  <div fxLayout="column">
    <app-button-loader
      class="landing-button-loader"
      [disabled]="loginSent || refreshFormGroup?.invalid || false"
      [loading]="loginSent || false"
      (action)="refreshToken()"
      [buttonStyle]="'mat-flat-button'"
      [text]="translations.submit"
    >
    </app-button-loader>
  </div>

  <mat-error
    *ngIf="loginFailedNoInternet"
    i18n="@@cannotReachTheServerPleaseMakeSureYouAreOnlineAndTryAgain"
    style="margin-top: 10px"
  >
    Cannot reach the server. Please make sure you are online and try again.
  </mat-error>

  <mat-divider class="in-line-divider"></mat-divider>

  <div class="mat-body top-margin" i18n="@@youCanAlsoLogoutToUseADifferentAccountColon">
    You can also logout to use a different account:
  </div>

  <button
    class="logout-button top-margin"
    type="reset"
    mat-raised-button
    color="warn"
    (click)="abort()"
    [class.margin-ionic]="layout.isIonic"
    i18n="@@logout"
  >
    Logout
  </button>
</form>
