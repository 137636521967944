import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  Injector,
  importProvidersFrom,
} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  CustomMatPaginatorIntl,
  DEFAULT_DROPZONE_CONFIG,
  environment,
  setAppInjector,
} from '@remberg/global/ui';
import { CoreUIModule, CustomRouteReuseStrategy, GlobalErrorHandler } from '@remberg/ui-core/core';
import { SharedMainModule } from '@remberg/ui-core/shared-main';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { QuillModule } from 'ngx-quill';
import localeCode from '../locale/code.locale';
import { AppRoutingModule } from './app-routing.module';

// register a copy of en-US named as 'code' as an additional locale for local development
registerLocaleData(localeCode);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      // Routing
      AppRoutingModule,
      // Service Worker
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.mobile,
      }),
      // Third-Party
      // Quill Readme: HINT: If you are using lazy loading modules,
      // you have to add QuillModule.forRoot() to your imports in your root module
      // to make sure the Config services is registered.
      QuillModule.forRoot(),
      // remberg
      CoreUIModule,
      SharedMainModule,
    ),
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    // The following error handler also includes the Sentry error handler.
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (injector: Injector): (() => void) =>
        () =>
          setAppInjector(injector),
      deps: [Injector],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
