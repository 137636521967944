import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { ADMIN_SUBDOMAIN } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { delay, firstValueFrom, of, tap } from 'rxjs';
import { TenantService } from '../services';
import { GlobalActions, GlobalSelectors, NavigationalActions, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class ActivationNavigationGuardService {
  constructor(
    private readonly store: Store<RootGlobalState>,
    private readonly logger: LogService,
    private readonly tenantService: TenantService,
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.logger.debug()('ActivationNavigationGuardService - canActivate');
    const isLoggedIn = await firstValueFrom(this.store.select(GlobalSelectors.selectIsLoggedIn));
    if (isLoggedIn) {
      this.store.dispatch(NavigationalActions.goToAuthenticatedDefaultPage());
      return false;
    }

    const isSubdomainRoutingActive = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsSubdomainRoutingActive),
    );
    if (!isSubdomainRoutingActive) {
      // We will login as admin
      return true;
    }

    const tenantSubdomain = await firstValueFrom(
      this.store.select(GlobalSelectors.selectTenantSubdomain),
    );
    if (!tenantSubdomain) {
      this.store.dispatch(NavigationalActions.goToApplicationRoot({}));
      return false;
    }

    if (tenantSubdomain !== ADMIN_SUBDOMAIN) {
      const tenant = await firstValueFrom(this.tenantService.getPublicBySubdomain(tenantSubdomain));

      if (!tenant?.isActivated) {
        const message = $localize`:@@tenantIsDeactivated:Tenant is deactivated`;
        this.store.dispatch(GlobalActions.showToaster({ toasterType: 'error', message }));

        return await firstValueFrom(
          of(false).pipe(
            delay(1000),
            tap(() => this.store.dispatch(NavigationalActions.goToApplicationRoot({}))),
          ),
        );
      }
    }

    return true;
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
