import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenancePlanService {
  private readonly MAINTENANCE_PLAN_URL = `${API_URL_PLACEHOLDER}/maintenance-plans/v2`;

  constructor(private readonly http: HttpClient) {}

  public findOne(id: string): Observable<any> {
    const params = new HttpParams();

    return this.http.get(`${this.MAINTENANCE_PLAN_URL}/${id}`, { params });
  }

  public findMany(options?: any): Observable<any> {
    let params = new HttpParams();

    if (options?.page !== undefined) {
      params = params.set('page', String(options.page));
    }
    if (options?.limit !== undefined) {
      params = params.set('limit', String(options.limit));
    }
    if (options?.sortDirection !== undefined) {
      params = params.set('sortDirection', options.sortDirection);
    }
    if (options?.sortField !== undefined) {
      params = params.set('sortField', options.sortField);
    }
    if (options?.search !== undefined) {
      params = params.set('search', options.search);
    }
    if (options?.filterObject !== undefined) {
      params = params.set('filterObject', JSON.stringify(options.filterObject));
    }
    if (options?.staticFilters?.length) {
      params = params.set('staticFilters', JSON.stringify(options.staticFilters));
    }
    for (const p of options?.populate || []) {
      params = params.append('populate', p);
    }

    return this.http.get(this.MAINTENANCE_PLAN_URL, {
      params,
    });
  }

  public findManyBasicByIds(body: any): Observable<any> {
    return this.http.put(`${this.MAINTENANCE_PLAN_URL}`, body);
  }

  public deleteOne(id: string): Observable<void> {
    return this.http.delete<void>(`${this.MAINTENANCE_PLAN_URL}/${id}`);
  }

  public create(body: any): Observable<any> {
    return this.http.post(this.MAINTENANCE_PLAN_URL, body);
  }

  public update(id: string, body: any): Observable<any> {
    return this.http.patch(`${this.MAINTENANCE_PLAN_URL}/${id}`, body);
  }
}
