import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdvancedFilter, AdvancedFilterOperatorEnum } from '@remberg/advanced-filters/common/main';
import { ASSETS_OFFLINE_SERVICE } from '@remberg/assets/ui/clients';
import { OrganizationCompatibility } from '@remberg/crm/common/main';
import { getAddressString } from '@remberg/global/common/core';
import {
  ApiResponse,
  LogService,
  SQLQueryParams,
  SyncDataTypesEnum,
  UnreachableCaseError,
  assertDefined,
  getStringID,
} from '@remberg/global/ui';
import { Part, PartsFilterEnum, PartsPopulateResourceTypeEnum } from '@remberg/parts/common/main';
import {
  PartsAdvancedFilterConfigKeys,
  PartsAdvancedStaticFilter,
  PartsFindManyOfflineQueryParams,
  PartsOfflineServiceInterface,
} from '@remberg/parts/ui/clients';
import {
  generateContainsSQLFilter,
  generateDoesNotContainSQLFilter,
  sqlFiltersHelper,
} from '../../helpers/sqlFiltersHelper';
import { RootGlobalState } from '../../store';
import { BaseOfflineService } from '../base.offline.service';
import { SqlDBService, dataTypeToServiceMap, populateArray } from '../sqlDB.service';

const enum ColumnNamesEnum {
  NAME = 'name',
  DESCRIPTION = 'description',
  SERIAL_NUMBER = 'serialNumber',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  ASSET_IDS = 'assetIds',
  ASSET_TYPE_IDS = 'assetTypeIds',
  DISTRIBUTOR_ORGANIZATION_IDS = 'distributorOrganizationIds',
  MANUFACTURER_ORGANIZATION_ID = 'manufacturerOrganizationId',
}

const PARAMS: SQLQueryParams<ColumnNamesEnum, PartsPopulateResourceTypeEnum, keyof Part> = {
  idString: '_id',
  tableName: SyncDataTypesEnum.PARTS,
  columns: {
    [ColumnNamesEnum.NAME]: {
      type: 'TEXT',
      valueFunction: (val: Part) => val?.name?.trim(),
      sortNoCase: true,
      isSearchColumn: true,
    },
    [ColumnNamesEnum.DESCRIPTION]: {
      type: 'TEXT',
      valueFunction: (val: Part) => val?.description?.trim(),
      sortNoCase: true,
    },
    [ColumnNamesEnum.SERIAL_NUMBER]: {
      type: 'TEXT',
      valueFunction: (val: Part) => val?.serialNumber?.trim(),
      isSearchColumn: true,
    },
    [ColumnNamesEnum.CREATED_AT]: {
      type: 'TEXT',
      valueFunction: (val: Part) => (val?.createdAt ? String(val.createdAt) : undefined),
    },
    [ColumnNamesEnum.UPDATED_AT]: {
      type: 'TEXT',
      valueFunction: (val: Part) => (val?.updatedAt ? String(val.updatedAt) : undefined),
    },
    [ColumnNamesEnum.ASSET_IDS]: {
      type: 'TEXT',
      valueFunction: (val: Part) =>
        (val?.assetIds as string[])?.map((id) => getStringID(id)).join(','),
    },
    [ColumnNamesEnum.ASSET_TYPE_IDS]: {
      type: 'TEXT',
      valueFunction: (val: Part) =>
        (val?.assetTypeIds as string[])?.map((id) => getStringID(id)).join(','),
    },
    [ColumnNamesEnum.DISTRIBUTOR_ORGANIZATION_IDS]: {
      type: 'TEXT',
      valueFunction: (val: Part) =>
        (val?.distributorOrganizationIds as string[])?.map((id) => getStringID(id)).join(','),
    },
    [ColumnNamesEnum.MANUFACTURER_ORGANIZATION_ID]: {
      type: 'TEXT',
      valueFunction: (val: Part) => getStringID(val?.manufacturerOrganizationId),
    },
  },
  populates: {
    [PartsPopulateResourceTypeEnum.ASSETS]: {
      targetKey: 'assets',
      populateFunction: async (val: Part, logger: LogService) => {
        const assetsOfflineService = inject(ASSETS_OFFLINE_SERVICE);

        return await assetsOfflineService.findManyByIds(val.assetIds);
      },
    },
    [PartsPopulateResourceTypeEnum.ASSET_TYPES]: {
      targetKey: 'assetTypes',
      populateFunction: async (val: Part, logger: LogService) => {
        const assetTypesOfflineService = inject(ASSETS_OFFLINE_SERVICE);

        return await assetTypesOfflineService.findManyByIds(val.assetIds);
      },
    },
    [PartsPopulateResourceTypeEnum.DISTRIBUTORS]: {
      targetKey: 'distributors',
      populateFunction: async (val: Part, logger: LogService) =>
        (
          await populateArray(
            SyncDataTypesEnum.ORGANIZATIONS,
            val?.distributorOrganizationIds,
            true,
            logger,
          )
        ).map((organization) => (organization as OrganizationCompatibility).crmData),
    },
    [PartsPopulateResourceTypeEnum.MANUFACTURER]: {
      targetKey: 'manufacturer',
      populateFunction: async (part: Part) => {
        const typeId = getStringID(part.manufacturerOrganizationId);
        const manufacturer =
          (
            (await dataTypeToServiceMap[SyncDataTypesEnum.ORGANIZATIONS]?.tryGetInstance(
              typeId,
              {},
            )) as OrganizationCompatibility
          )?.crmData ?? {};

        return {
          ...manufacturer,
          shippingAddress: getAddressString(manufacturer?.shippingAddress),
        };
      },
    },
  },
};

const PART_FILTER_ENUM_TO_COLUMN_NAME: Record<PartsAdvancedFilterConfigKeys, string | undefined> = {
  [PartsFilterEnum.ASSET]: ColumnNamesEnum.ASSET_IDS,
  [PartsFilterEnum.ASSET_TYPE]: ColumnNamesEnum.ASSET_IDS,
  [PartsFilterEnum.CREATED]: ColumnNamesEnum.CREATED_AT,
  [PartsFilterEnum.UPDATED]: ColumnNamesEnum.UPDATED_AT,
  [PartsFilterEnum.DISTRIBUTOR]: ColumnNamesEnum.DISTRIBUTOR_ORGANIZATION_IDS,
  [PartsFilterEnum.MANUFACTURER]: ColumnNamesEnum.MANUFACTURER_ORGANIZATION_ID,
  [PartsFilterEnum.IS_PUBLIC]: undefined,
};

@Injectable()
export class PartsOfflineService
  extends BaseOfflineService<Part, PartsFilterEnum>
  implements PartsOfflineServiceInterface
{
  constructor(dbService: SqlDBService, logger: LogService, store: Store<RootGlobalState>) {
    super(dbService, PARAMS, logger, store);
  }

  public async getPartsWithCount(
    partsFindManyOfflineQueryParams: PartsFindManyOfflineQueryParams,
  ): Promise<ApiResponse<Part[]>> {
    this.logger.debug()(
      `[getPartsWithCount]: params: ${JSON.stringify(partsFindManyOfflineQueryParams)}`,
    );
    return super.getManyItemsWithCount(partsFindManyOfflineQueryParams);
  }

  public override getStaticFiltersString(
    staticFilters: PartsAdvancedStaticFilter[] | undefined,
  ): string[] {
    const filterQueries = [];
    for (const staticFilter of staticFilters ?? []) {
      switch (staticFilter.identifier) {
        case PartsFilterEnum.ASSET_TYPE:
        case PartsFilterEnum.ASSET: {
          const columnName = PART_FILTER_ENUM_TO_COLUMN_NAME[staticFilter.identifier];
          if (!columnName) {
            break;
          }
          const filter = this.generateContainsFilters(staticFilter, columnName);
          if (!filter) {
            this.logger.error()(
              `[getPartsWithCount]: Static filter operator: ${staticFilter.operator} unsupported for ${staticFilter.identifier}`,
            );
            break;
          }
          filterQueries.push(filter);
          break;
        }
        case PartsFilterEnum.IS_PUBLIC:
        case PartsFilterEnum.CREATED:
        case PartsFilterEnum.UPDATED:
        case PartsFilterEnum.DISTRIBUTOR:
        case PartsFilterEnum.MANUFACTURER:
        case PartsFilterEnum.ASSET_OR_ASSET_TYPE:
          this.logger.error()(
            `[getPartsWithCount]: Static filter is not supported for field: ${staticFilter.identifier}`,
          );
          break;

        default:
          this.logger.error()(
            `[getPartsWithCount]: Specified unknown static filter: ${staticFilter.identifier}`,
          );
          throw new UnreachableCaseError(staticFilter.identifier);
      }
    }
    return filterQueries;
  }
  public override getAdvancedFilterString(
    filter: AdvancedFilter<PartsAdvancedFilterConfigKeys>,
  ): string {
    const columnName = PART_FILTER_ENUM_TO_COLUMN_NAME[filter.identifier];
    if (!columnName) {
      return '';
    }
    switch (filter.identifier) {
      case PartsFilterEnum.DISTRIBUTOR:
      case PartsFilterEnum.ASSET_TYPE:
      case PartsFilterEnum.ASSET: {
        return (
          this.generateContainsFilters(filter, columnName) ??
          sqlFiltersHelper(filter, `${PARAMS.tableName}.${columnName}`)
        );
      }
      case PartsFilterEnum.MANUFACTURER:
        return sqlFiltersHelper(filter, `${PARAMS.tableName}.${columnName}`);
      case PartsFilterEnum.UPDATED:
      case PartsFilterEnum.CREATED:
        return sqlFiltersHelper(filter, `${PARAMS.tableName}.${columnName}`);
      case PartsFilterEnum.IS_PUBLIC:
        return '';
      default:
        new UnreachableCaseError(filter.identifier);
        throw new Error(
          `[getAdvancedFilterString]: Field ${filter.identifier} can't be used as a filter identifier`,
        );
    }
  }

  private generateContainsFilters(
    filter: PartsAdvancedStaticFilter | AdvancedFilter<PartsAdvancedFilterConfigKeys>,
    columnName: string,
  ): string | undefined {
    assertDefined(
      filter.value,
      `[generateContainsFilters]: Advanced filter value should be defined for filter operator ${filter.operator}`,
    );
    switch (filter.operator) {
      case AdvancedFilterOperatorEnum.IS:
        return generateContainsSQLFilter(columnName, filter.value);
      case AdvancedFilterOperatorEnum.IS_NOT:
        return generateDoesNotContainSQLFilter(columnName, filter.value);
    }
    return;
  }
}
