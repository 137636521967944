import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule, StoreDevtoolsOptions } from '@ngrx/store-devtools';
import { TRACKING_SERVICE } from '@remberg/analytics/ui/clients';
import { ASSETS_OFFLINE_SERVICE, ASSET_TYPES_OFFLINE_SERVICE } from '@remberg/assets/ui/clients';
import { CONTACTS_OFFLINE_SERVICE, ORGANIZATIONS_OFFLINE_SERVICE } from '@remberg/crm/ui/clients';
import { PLATFORM_FILES_OFFLINE_SERVICE } from '@remberg/files/ui/clients';
import {
  FORM_INSTANCE_OFFLINE_SERVICE,
  FORM_TEMPLATE_OFFLINE_SERVICE,
} from '@remberg/forms/ui/clients';
import { CONNECTIVITY_SERVICE, INTERCOM_CONFIG, LogService, environment } from '@remberg/global/ui';
import { PARTS_OFFLINE_SERVICE } from '@remberg/parts/ui/clients';
import { SERVICE_CASE_OFFLINE_SERVICE } from '@remberg/tickets/ui/clients';
import {
  WORK_ORDER_2_OFFLINE_SERVICE,
  WORK_ORDER_STATUS_2_OFFLINE_SERVICE,
  WORK_ORDER_TYPE_2_OFFLINE_SERVICE,
} from '@remberg/work-orders/ui/clients';
import * as Sentry from '@sentry/angular';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { initializeGlobalState } from './global-initialization';
import { ApiUrlInterceptor, SkipCacheInterceptor } from './interceptors';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { LogInterceptor } from './interceptors/log.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { IonicSharedModule } from './ionic-shared.module';
import {
  WorkOrder2OfflineService,
  WorkOrderStatus2OfflineService,
  WorkOrderType2OfflineService,
} from './services';
import { ConnectivityService } from './services/connectivity.service';
import { AssetTypesOfflineService } from './services/offline/asset-types.offline.service';
import { AssetsOfflineService } from './services/offline/assets.offline.service';
import { ContactOfflineService } from './services/offline/contact.offline.service';
import { FormInstanceOfflineService } from './services/offline/formInstance.offline.service';
import { FormTemplateOfflineService } from './services/offline/formTemplate.offline.service';
import { OrganizationOfflineService } from './services/offline/organization.offline.service';
import { PartsOfflineService } from './services/offline/parts.offline.service';
import { PlatformFilesOfflineService } from './services/offline/platform-files.offline.service';
import { ServiceCaseOfflineService } from './services/offline/serviceCase.offline.service';
import { AnalyticsTrackingService } from './services/tracking.service';
import { CORE_UI_EFFECTS, RootGlobalState, allReducers, metaReducers } from './store';

const options: StoreDevtoolsOptions = {
  name: 'remberg',
  logOnly: environment.production, // this means log with read only access for production
  features: {
    pause: environment.mobile, // pauses recording actions and state changes when set to true
  },
};
const StoreDevModules =
  environment.mobile || !environment.debug ? [] : [StoreDevtoolsModule.instrument(options)];

@NgModule({
  imports: [
    CommonModule,
    IntercomModule.forRoot(INTERCOM_CONFIG),
    IonicSharedModule,
    StoreModule.forRoot(allReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    EffectsModule.forRoot(CORE_UI_EFFECTS),
    ...StoreDevModules,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SkipCacheInterceptor, multi: true },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (store: Store<RootGlobalState>, logger: LogService) => async (): Promise<void> => {
          await initializeGlobalState(store, logger);
        },
      deps: [Store<RootGlobalState>, LogService, Sentry.TraceService],
      multi: true,
    },
    { provide: ORGANIZATIONS_OFFLINE_SERVICE, useClass: OrganizationOfflineService },
    { provide: CONTACTS_OFFLINE_SERVICE, useClass: ContactOfflineService },
    { provide: PARTS_OFFLINE_SERVICE, useClass: PartsOfflineService },
    { provide: PLATFORM_FILES_OFFLINE_SERVICE, useClass: PlatformFilesOfflineService },
    { provide: FORM_INSTANCE_OFFLINE_SERVICE, useClass: FormInstanceOfflineService },
    { provide: FORM_TEMPLATE_OFFLINE_SERVICE, useClass: FormTemplateOfflineService },
    { provide: WORK_ORDER_2_OFFLINE_SERVICE, useClass: WorkOrder2OfflineService },
    { provide: WORK_ORDER_TYPE_2_OFFLINE_SERVICE, useClass: WorkOrderType2OfflineService },
    { provide: WORK_ORDER_STATUS_2_OFFLINE_SERVICE, useClass: WorkOrderStatus2OfflineService },
    { provide: CONNECTIVITY_SERVICE, useClass: ConnectivityService },
    { provide: ASSETS_OFFLINE_SERVICE, useClass: AssetsOfflineService },
    { provide: ASSET_TYPES_OFFLINE_SERVICE, useClass: AssetTypesOfflineService },
    { provide: TRACKING_SERVICE, useClass: AnalyticsTrackingService },
    { provide: SERVICE_CASE_OFFLINE_SERVICE, useClass: ServiceCaseOfflineService },
  ],
})
export class CoreUIModule {
  constructor(@Optional() @SkipSelf() core: CoreUIModule) {
    if (core) {
      throw new Error('You should import the core module only in the root module');
    }
  }
}
