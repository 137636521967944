<div fxLayout="column">
  <div class="confirmation-text-wrapper">
    <ng-template #titleTemplate>
      <!-- Title -->
      <h2
        *ngIf="title"
        [ngClass]="{
          'text-title-center': title?.position === 'center',
          'text-title-left': title?.position === 'left',
        }"
      >
        {{ title?.text }}
      </h2>
    </ng-template>

    <!-- Simple Spinner -->
    <div *ngIf="hasAnimation">
      <div
        class="loader-container loader-container-relative"
        [class]="'top-icon ' + 'icon-' + icon?.color"
      >
        <app-loader [color]="icon?.color" [diameter]="60" [strokeWidth]="6"></app-loader>
      </div>
      <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
    </div>

    <!-- Autosaving Spinner -->
    <div class="header-wrapper" *ngIf="showAutoSavingState">
      <div
        *ngIf="autoSavingState === AutoSavingStateEnum.SAVING"
        class="loader-container loader-container-relative"
        [class]="'top-icon ' + 'icon-' + icon?.color"
      >
        <app-loader [diameter]="60" [strokeWidth]="6"></app-loader>
      </div>

      <div *ngIf="autoSavingState === AutoSavingStateEnum.DONE">
        <mat-icon [class]="'top-icon ' + 'icon-' + icon?.color"> check </mat-icon>
      </div>

      <ng-container *ngIf="autoSavingState === AutoSavingStateEnum.SAVING">
        <h2
          i18n="@@savingChangesTripleDot"
          [ngClass]="{
            'text-title-center': title?.position === 'center',
            'text-title-left': title?.position === 'left',
          }"
        >
          Saving changes ...
        </h2>
      </ng-container>

      <ng-container *ngIf="autoSavingState === AutoSavingStateEnum.DONE">
        <h2
          i18n="@@allChangesSaved"
          [ngClass]="{
            'text-title-center': title?.position === 'center',
            'text-title-left': title?.position === 'left',
          }"
        >
          All changes saved
        </h2>
      </ng-container>
    </div>

    <!-- Icon + Header -->
    <div class="header-wrapper" *ngIf="!showAutoSavingState && !hasAnimation && (title || icon)">
      <!-- Icon -->
      <div *ngIf="icon && icon.icon">
        <mat-icon [class]="'top-icon ' + 'icon-' + icon?.color">{{ icon.icon }}</mat-icon>
      </div>
      <ng-template [ngTemplateOutlet]="titleTemplate"></ng-template>
    </div>

    <!-- Description -->
    <div *ngIf="description && description.text && description.text.length > 0">
      <p
        class="mat-body-2"
        [ngClass]="{
          'text-description-center': description?.position === 'center',
          'text-description-left': description?.position === 'left',
          'text-description-justify': description?.position === 'justify',
          'text-description-red-color': description?.redColor,
        }"
        *ngFor="let desc of description?.text"
      >
        {{ desc }}
      </p>
    </div>

    <!-- Progress Bars -->
    <ng-container *ngIf="progressBarConfig">
      <div class="progress-bar-item">
        <div style="display: flex; justify-content: space-between; margin-bottom: 5px">
          <p class="progress-bar-text">{{ progressBarConfig.text }}</p>
          <p style="white-space: nowrap" class="progress-bar-text">
            {{ (progressBarConfig.progress | number: '1.0-0') + ' %' }}
          </p>
        </div>
        <mat-progress-bar
          matLine
          mode="determinate"
          [value]="progressBarConfig.progress"
        ></mat-progress-bar>
      </div>
    </ng-container>

    <!-- Show again -->
    <div
      *ngIf="showDoNotAskAgain"
      [ngClass]="{ 'show-not-again-center': showDoNotAskAgainTextCentered }"
      [ngClass]="{
        'text-description-center': description?.position === 'center',
        'text-description-left': description?.position === 'left',
        'text-description-justify': description?.position === 'justify',
      }"
    >
      <mat-checkbox
        [(ngModel)]="checked"
        i18n="@@doNotShowThisMessageAgain"
        class="mat-checkbox-top-vertical-align"
      >
        Do not show this message again.
      </mat-checkbox>
    </div>
  </div>

  <!-- Buttons (distinguish horizontal and vertical) -->
  <div
    [fxLayout]="buttonsDirection === 'horizontal' ? 'row wrap' : 'column wrap'"
    [ngStyle]="buttonsDirection === 'horizontal' ? { 'justify-content': 'space-between' } : {}"
  >
    <ng-container *ngFor="let button of buttons">
      <button
        *ngIf="
          !progressBarConfig ||
          (!progressBarCompleted && button.category !== 'success') ||
          (progressBarCompleted && button.category === 'success')
        "
        mat-raised-button
        [ngStyle]="buttonsDirection === 'vertical' ? { 'margin-top': '10px' } : {}"
        [color]="button?.color"
        (click)="buttonPressed(button)"
        attr.data-test-id="{{ button.dataTestId }}"
      >
        <ng-container>{{ button.text }}</ng-container>
      </button>
    </ng-container>
  </div>
</div>
