import { Store } from '@ngrx/store';
import { FeatureFlagEnum, InternalFeatureFlagValues } from '@remberg/global/common/core';
import { FrontendFeatureFlagEnum } from '@remberg/global/ui';
import { Permissions } from '@remberg/users/common/main';
import { Observable, combineLatest, map, of } from 'rxjs';
import { FeatureFlagKey, PermissionAction } from '../definitions/permission-checks';
import { GlobalSelectors, RootGlobalState } from '../store';

export function checkUserPermission(
  action: PermissionAction,
  permissions: Permissions,
  features: InternalFeatureFlagValues,
  viewCheck?: boolean,
): boolean {
  if (!permissions) {
    return false;
  }

  // The prefix of action (first part before "_") is the feature flag
  // The second part of action (after "_") is the exact permission
  const [first, second] = action.split('_') as [FeatureFlagKey, string | undefined];

  // Step 4: check account feature flag
  if (!checkFeatureFlag(first, features)) {
    return false;
  }
  // Step 5: check for 'enabled'
  if (!hasEnabledPermission(first, permissions)) {
    return false;
  }

  // Step 6: if check for enabled, view, featureFlag return here
  if (viewCheck) {
    return true;
  }

  // Step 7: check for specific action
  if ((permissions as any)[first] && second) {
    return (permissions as any)[first][second];
  }
  return false;
}

export function checkFeatureFlag(
  flag: FeatureFlagKey,
  features: InternalFeatureFlagValues,
): boolean {
  if (flag === 'tickets') {
    return !!features[FeatureFlagEnum.CASES];
  }
  if (
    flag === FrontendFeatureFlagEnum.ORGANIZATIONS ||
    flag === FrontendFeatureFlagEnum.SETTINGS ||
    flag === FrontendFeatureFlagEnum.USERS
  ) {
    return true;
  }
  return !!features[flag];
}

function hasEnabledPermission(feature: FeatureFlagKey, permissions: Permissions): boolean {
  switch (feature) {
    case FeatureFlagEnum.AI_COPILOT:
    case FeatureFlagEnum.ASSETS:
    case FeatureFlagEnum.FILES:
    case FeatureFlagEnum.FORMS:
    case FeatureFlagEnum.WORKORDERS_MAINTENANCEPLANS:
    case FeatureFlagEnum.QRCODES:
    case FeatureFlagEnum.TASKS:
    case FeatureFlagEnum.PARTS:
    case FeatureFlagEnum.WORK_ORDERS_TEMPORARY:
    case FeatureFlagEnum.APPOINTMENTS_TEMPORARY:
    case FeatureFlagEnum.DASHBOARDS:
    case 'tickets':
      return hasEnabledRightHelper(feature, permissions);
    case FeatureFlagEnum.CASES:
      // Edge case since feature flag for cases does not match the rights prefix
      return hasEnabledRightHelper('tickets', permissions);
    case FrontendFeatureFlagEnum.USERS:
    case FrontendFeatureFlagEnum.ORGANIZATIONS:
    case FrontendFeatureFlagEnum.SETTINGS:
    case FeatureFlagEnum.MAINTENANCE_PLANS_TEMPORARY:
    case FeatureFlagEnum.ASSETS_HIERARCHY:
    case FeatureFlagEnum.FILES_DOWNLOAD:
    case FeatureFlagEnum.CASES_REQUIRE_OEM_FEEDBACK:
      return true;
    default:
      return false;
  }
}

function hasEnabledRightHelper(
  first: keyof Omit<Permissions, 'organizations' | 'users'>,
  permissions: Permissions,
): boolean {
  return !!permissions[first]?.enabled;
}

export function getHasAccess$(
  store: Store<RootGlobalState>,
  action?: PermissionAction,
  featureFlag?: FeatureFlagKey,
  viewCheck?: boolean,
): Observable<boolean> {
  const permission$ = action
    ? store.select(GlobalSelectors.selectHasPermission(action, viewCheck))
    : of(true);
  const featureFlag$ = featureFlag
    ? store.select(GlobalSelectors.selectHasFeature(featureFlag))
    : of(true);

  return combineLatest([permission$, featureFlag$]).pipe(
    map(([hasPermission, hasFeature]) => hasPermission && hasFeature),
  );
}
