export enum RelatedOrganizationEnum {
  ASSET_MAIN_ORGANIZATION = 'assetMainOrganization',
  MANUAL = 'manual',
}

export enum RelatedContactsEnum {
  ASSET_CONTACTS = 'assetContacts',
  MANUAL = 'manual',
}

export enum AddressEnum {
  ASSET = 'asset',
  ASSET_MAIN_ORGANIZATION_BILLING = 'assetMainOrganizationBilling',
  ASSET_MAIN_ORGANIZATION_SHIPPING = 'assetMainOrganizationShipping',
  ORGANIZATION_BILLING = 'organizationBilling',
  ORGANIZATION_SHIPPING = 'organizationShipping',
  MANUAL = 'manual',
}
