import { Complete } from '@remberg/global/common/core';
import { Tenant, TenantPublic } from '../definitions';

export function tenantToTenantPublic(tenant: Tenant): Complete<TenantPublic>;
export function tenantToTenantPublic(tenant: undefined): undefined;
export function tenantToTenantPublic(tenant?: Tenant): Complete<TenantPublic> | undefined;
export function tenantToTenantPublic(tenant?: Tenant): Complete<TenantPublic> | undefined {
  return (
    tenant && {
      tenantId: tenant._id,
      ownerOrganizationId: tenant.ownerOrganizationId,
      externalFeatureFlags: tenant.externalFeatureFlags,
      internalFeatureFlags: tenant.internalFeatureFlags,
      subdomain: tenant.subdomain,
      theme: tenant.theme,
      isStatisticsEnabled: tenant.isStatisticsEnabled,
      isActivated: tenant.isActivated,
      configuration: tenant.configuration,
    }
  );
}
