import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { TasksPermissionEnum } from '@remberg/tasks/common/main';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store/global';

export const tasksGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const store = inject(Store);
  const router = inject(Router);
  const logger = inject(LogService);
  const toastr = inject(ToastrService);

  const hasPermission = await firstValueFrom(
    store.select(GlobalSelectors.selectHasPermission(TasksPermissionEnum.TASKS_ENABLED)),
  );

  if (hasPermission) return true;

  logger.info()('Tasks Guard: Redirecting from: ' + router.url);
  toastr.warning($localize`:@@noTasksFeatureAccess:Tasks feature access is not granted.`);
  void router.navigate(['/']);
  return false;
};
