import { ActivityModelNameEnum } from './activity-model-name.enum';

/**
 * @description Enum for the activity model path param.`
 */
export enum ActivityModelPathParamEnum {
  CASE = 'case',
  WORK_ORDER = 'work-order',
  ASSET = 'asset',
  ASSET_TYPE = 'asset-type',
  FORM_INSTANCE = 'form-instance',
  NOTE = 'note',
  PLATFORM_FILE = 'platform-file',
  EMAIL = 'email',
  TASK = 'task',
  TICKET2 = 'ticket',
}

export const MAP_ACTIVITY_MODEL_PATH_PARAM_TO_MODEL_NAME_ENUM: Record<
  ActivityModelPathParamEnum,
  ActivityModelNameEnum
> = {
  [ActivityModelPathParamEnum.CASE]: ActivityModelNameEnum.CASE,
  [ActivityModelPathParamEnum.WORK_ORDER]: ActivityModelNameEnum.WORK_ORDER,
  [ActivityModelPathParamEnum.ASSET]: ActivityModelNameEnum.ASSET,
  [ActivityModelPathParamEnum.ASSET_TYPE]: ActivityModelNameEnum.ASSET_TYPE,
  [ActivityModelPathParamEnum.FORM_INSTANCE]: ActivityModelNameEnum.FORM_INSTANCE,
  [ActivityModelPathParamEnum.NOTE]: ActivityModelNameEnum.NOTE,
  [ActivityModelPathParamEnum.PLATFORM_FILE]: ActivityModelNameEnum.PLATFORM_FILE,
  [ActivityModelPathParamEnum.EMAIL]: ActivityModelNameEnum.EMAIL,
  [ActivityModelPathParamEnum.TASK]: ActivityModelNameEnum.TASK,
  [ActivityModelPathParamEnum.TICKET2]: ActivityModelNameEnum.TICKET2,
};
