import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LogService } from '@remberg/global/ui';
import { TicketsPermissionEnum } from '@remberg/tickets2/common/main';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store';

export const ticketsGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const store = inject(Store);
  const router = inject(Router);
  const logger = inject(LogService);
  const toastr = inject(ToastrService);

  const hasPermission = await firstValueFrom(
    store.select(GlobalSelectors.selectHasPermission(TicketsPermissionEnum.TICKETS_ENABLED)),
  );

  if (hasPermission) return true;

  logger.info()('Tickets Guard: Redirecting from: ' + router.url);
  toastr.warning($localize`:@@noTasksFeature:Account feature "Tickets" is not granted.`);
  void router.navigate(['/']);
  return false;
};
