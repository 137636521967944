import { MainRoutes } from '../definitions';

/**
 * Create a URL path from a main route via a list of segments.
 * Joins every segment with a single forward slash and removes empty segments.
 * @param segments - The individual segments of the URL path.
 */
export function urlPath(segments: [MainRoutes, ...string[]]): string {
  return ('/' + segments.filter(Boolean).join('/')).replace(/\/+/g, '/').replace(/\s/g, '-');
}
