import { Address, UnknownOr } from '@remberg/global/common/core';
import { Asset } from '../../assets';
import { AssetStatusEnum } from '../../definitions';

export function isProductCompatibility(
  argument?: string | { _id: string } | UnknownOr<ProductCompatibility>,
): argument is ProductCompatibility {
  return !!argument && !!(argument as ProductCompatibility).productType;
}

export const mapAssetToProductCompatibility = (asset: Asset): ProductCompatibility => ({
  _id: asset._id,
  tenantId: asset.tenantId,
  serialNumber: asset.serialNumber,
  productTypeName: asset.assetTypeLabel,
  customerOrganization: asset.relatedOrganizationIds[0],
  location: asset.location,
  productType: {
    _id: asset.assetTypeId,
    label: asset.assetTypeLabel,
    image: asset.assetTypeImageId,
  },
  status: asset.status,
  userGroups: asset.userGroupIds,
  productImageId: asset.assetImageId,
});

/**
 * @deprecated Use non-compatibility interfaces instead
 * This interface is only used in the frontend and represents the object that is returned from the old backend.
 * */
export interface ProductCompatibility {
  _id: string;
  tenantId: string;
  serialNumber: string;
  productType: { _id: string; label: string; image?: string };
  productTypeName: string;
  manufacturerOrganization?: string;
  customerOrganization?: string;
  location?: Address;
  status?: AssetStatusEnum;
  userGroups?: string[];
  productImageId?: string;
}
