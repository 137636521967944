import { VisualizationTypeEnum, isDefined } from '@remberg/global/common/core';

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function generateObjectId(): string {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}

export function objectSize(obj: any): number {
  let size = 0,
    key;
  for (key of Object.keys(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      size++;
    }
  }
  return size;
}

export function chunkArrayInGroups(arr: any, size: number): any[] {
  const myArray = [];
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export async function retry(fn: () => any, n: number, optionalThis?: boolean) {
  for (let i = 0; i < n; i++) {
    try {
      if (optionalThis) {
        return await fn.call(optionalThis);
      } else {
        return await fn();
      }
    } catch (err) {
      // console.log(err);
    }
  }

  throw new Error(`Failed retrying function ${n} times.`);
}
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`);
  }
}

export class UnsupportedVisualizationTypeError extends Error {
  constructor(visualizationType: VisualizationTypeEnum | undefined) {
    super(`Unsupported visualization type ${visualizationType} specified`);
  }
}

export function jsonToBase64(json: any): string {
  const data = JSON.stringify(json);
  return btoa(unescape(encodeURIComponent(data)));
}

export function base64ToJson(base64: string): any {
  return JSON.parse(decodeURIComponent(escape(atob(base64))));
}

/** Creates an array and fills it with values. */
export function createArray<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

export function isDefinedAndNotEmpty<T>(obj: T | undefined | null | ''): obj is T {
  return isDefined(obj) && obj !== '';
}

/**
 * Helps to return strict typed objects or keys of certain type
 * when it's hard or cumbersome to define it explicitly
 *
 * @example
 * interface Foo {
 *  name: string;
 * }
 *
 * function getFooKey(...) {
 *   ...
 *
 * // return 'name' as keyof Foo -- wouldn't produce an error if the key name changes
 *    return as<keyof Foo>('name'); // - works just fine in that case
 * }
 *
 * .pipe(
 *    map(input => {
 *    ... some logic ...
 *
 *    return as<SomeItem>({ // now IntelliSence will help you to construct SomeItem correctly
 *       ...
 *    });
 * }
 *
 * @deprecated use as from '@remberg/global/common/core'
 */
export function as<T>(obj: T): T {
  return obj;
}

export function assertDefined<T>(obj: T | undefined | null, message?: string): asserts obj is T {
  if (obj === undefined || obj === null) {
    throw new Error(message || 'Assert: object is undefined or null');
  }
}

export function isNotNullOrUndefined<T>(input: T | undefined | null): input is T {
  return input !== undefined && input !== null;
}

export function getDefinedOrThrow<T>(obj: T | undefined | null, message?: string): T {
  if (!isDefined(obj)) {
    throw new Error(message || 'Ensure: object is undefined or null');
  }

  return obj;
}

export function hasOwnProperty<T>(obj: T | undefined, propertyName: keyof T): boolean {
  return isDefined(obj) && Object.prototype.hasOwnProperty.call(obj, propertyName);
}

export function filterEmptyProps<T extends object>(obj: T): T {
  return filterObjectProps(obj, isDefinedAndNotEmpty);
}
export function filterNullOrUndefinedProps<T extends object>(obj: T): T {
  return filterObjectProps(obj, isDefined);
}
export function filterObjectProps<T extends object>(
  obj: T,
  filterFunction: (prop: unknown) => boolean,
): T {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => filterFunction(value))) as T;
}
