/**
 * Represents DOMRect information in a serializable form.
 * Used for dialog positioning while avoiding passing unserializable objects through ngrx.
 */
export interface DOMRectSnapshot {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
  x: number;
  y: number;
}

export function toDOMRectSnapshot(domRect: DOMRect): DOMRectSnapshot {
  return {
    top: domRect.top,
    right: domRect.right,
    bottom: domRect.bottom,
    left: domRect.left,
    width: domRect.width,
    height: domRect.height,
    x: domRect.x,
    y: domRect.y,
  };
}

export function getBoundingClientRectSnapshot(element: HTMLElement): DOMRectSnapshot {
  return toDOMRectSnapshot(element.getBoundingClientRect());
}
