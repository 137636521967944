import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface ToastrMessage {
  title: string;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(public readonly toastrService: ToastrService) {}

  /**
   * Function used for copy text to clipboard
   * @param text string to copy
   * @param toastrMessage toastr message to show after copy
   */
  public copyToClipboard(text: string, toastrMessage?: ToastrMessage): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (toastrMessage) {
          this.toastrService.info(toastrMessage.message, toastrMessage.title);
        } else {
          this.toastrService.info('', $localize`:@@copiedText:Copied Text`);
        }
      })
      .catch(() => this.toastrService.error('', $localize`:@@copyError:Copy Error`));
  }
}
