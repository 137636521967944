import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { PartPermissionsEnum } from '@remberg/parts/common/main';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store';

export const partsGuardFn: CanActivateFn = async (): Promise<boolean> => {
  const store = inject(Store);
  const router = inject(Router);
  const logger = inject(LogService);

  const isTenantOwner = await firstValueFrom(store.select(GlobalSelectors.selectIsTenantOwner));

  const hasAccess = await firstValueFrom(
    store.select(
      GlobalSelectors.selectHasAccess(
        [FeatureFlagEnum.PARTS],
        [PartPermissionsEnum.PARTS_ENABLED, PartPermissionsEnum.PARTS_VIEW_IN_OVERVIEW],
      ),
    ),
  );

  if (isTenantOwner && hasAccess) return true;

  logger.info()('Parts Guard: Redirecting from: ' + router.url);
  void router.navigate(['/']);
  return false;
};
