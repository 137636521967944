import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LanguageCodeEnum } from '@remberg/global/common/core';
import { EMAIL_VALIDATION_REGEX, getMappedLocale } from '@remberg/global/ui';
import { GlobalActions, RootGlobalState } from '@remberg/ui-core/core';
import { RembergUsersService } from '@remberg/users/ui/clients';
import { firstValueFrom } from 'rxjs';

export interface AccessPopupData {
  isRegistrationOnly: boolean;
  tenantId: string;
  shouldNavigateToLoginOnBackClick: boolean;
  assetId?: string;
  workspaceName: string;
  isContact: boolean;
}

interface AccessPopupFormGroup {
  email: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  jobPosition: FormControl<string>;
  company: FormControl<string>;
}

@Component({
  selector: 'app-access-popup',
  templateUrl: './access-popup.component.html',
  styleUrls: ['./access-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessPopupComponent {
  protected readonly accessRequestFormGroup = new FormGroup<AccessPopupFormGroup>({
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern(EMAIL_VALIDATION_REGEX)],
    }),
    firstName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    lastName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    company: new FormControl('', { nonNullable: true }),
    jobPosition: new FormControl('', { nonNullable: true }),
  });
  protected isProcessingRequest = false;
  protected isRegistrationOnly = false;
  protected workspaceName?: string;
  protected dataPrivacyUrl?: string;
  protected isContact = false;

  protected readonly transtations = {
    requestAccess: $localize`:@@requestAccess:Request Access`,
    login: $localize`:@@login:Login`,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: AccessPopupData,
    private readonly dialogRef: MatDialogRef<AccessPopupComponent>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly rembergUsersService: RembergUsersService,
    private readonly store: Store<RootGlobalState>,
    @Inject(LOCALE_ID) private readonly locale: string,
  ) {
    this.workspaceName = data.workspaceName;
    this.isRegistrationOnly = data.isRegistrationOnly;
    this.dataPrivacyUrl = this.getDataPrivacyUrl();
    this.isContact = data.isContact;
    if (this.isContact) {
      this.accessRequestFormGroup.get('company')?.addValidators([Validators.required]);
      this.accessRequestFormGroup.get('company')?.updateValueAndValidity();
      this.accessRequestFormGroup.get('jobPosition')?.addValidators([Validators.required]);
      this.accessRequestFormGroup.get('jobPosition')?.updateValueAndValidity();
    }
  }

  protected onBackClicked(): void {
    this.dialogRef.close();
    if (this.data.shouldNavigateToLoginOnBackClick) {
      this.router.navigate(['/login'], { queryParams: { target: '/link/' + this.data.assetId } });
    }
  }

  protected onLoginClicked(): void {
    this.dialogRef.close();
    this.router.navigate(['/login'], {
      queryParams: { target: '/assets/detail/' + this.data.assetId },
    });
  }

  protected onRequestAccessClicked(): void {
    this.isRegistrationOnly = true;
  }

  protected async onAccessRequestSubmitted(): Promise<void> {
    this.isProcessingRequest = true;
    this.cdRef.markForCheck();

    const { email, firstName, lastName, company, jobPosition } = this.accessRequestFormGroup.value;
    const { tenantId, assetId, isContact } = this.data;

    if (email && firstName && lastName) {
      try {
        await firstValueFrom(
          this.rembergUsersService.requestAccess({
            tenantId,
            email,
            firstName,
            lastName,
            assetId,
            jobPosition,
            isContact,
            lang: getMappedLocale(this.locale),
            ...(isContact && { company }),
          }),
        );

        this.store.dispatch(
          GlobalActions.showToaster({
            toasterType: 'success',
            message: $localize`:@@pleaseWaitForEmailInstructionsOnHowToContinueDot:Please wait for email instructions on how to continue.`,
            title: $localize`:@@weAreProcessingYourRequest:We are processing your request!`,
          }),
        );
      } catch (error) {
        this.store.dispatch(
          GlobalActions.showToaster({
            toasterType: 'error',
            message: $localize`:@@couldNotProcessRequest:Your request could not be processed; Please try again later.`,
          }),
        );
      }
      this.isProcessingRequest = false;
      this.dialogRef.close();
    }
    this.cdRef.markForCheck();
  }

  protected getDataPrivacyUrl(): string {
    const currentLang = getMappedLocale(this.locale);
    switch (currentLang) {
      case LanguageCodeEnum.DE:
        return 'https://www.remberg.de/privacy-policy-app/';
      case LanguageCodeEnum.EN_US:
      default:
        return 'https://www.remberg.de/en/privacy-policy-app/';
    }
  }

  protected onClose(): void {
    this.dialogRef.close();
  }
}
