import { UnknownOr } from '../definitions';
import { isUnknownObject } from './unknown-object-utils';

function compareByKey<T extends { _id: string }>(
  key: keyof T,
  a: UnknownOr<T>,
  b: UnknownOr<T>,
): number {
  if (isUnknownObject(a)) return isUnknownObject(b) ? 0 : 1;
  if (isUnknownObject(b)) return -1;
  return `${a[key]}`.localeCompare(`${b[key]}`);
}

export function createCompareByKey<T extends { _id: string }>(key: keyof T) {
  return (a: UnknownOr<T>, b: UnknownOr<T>) => compareByKey<T>(key, a, b);
}
