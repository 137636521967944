import { Inject, Injectable } from '@angular/core';
import { ContactBasic } from '@remberg/crm/common/base';
import { OrganizationRaw, contactRawToContactBasic } from '@remberg/crm/common/main';
import {
  CONTACTS_OFFLINE_SERVICE,
  ContactsOfflineServiceInterface,
  ORGANIZATIONS_OFFLINE_SERVICE,
  OrganizationOfflineServiceInterface,
} from '@remberg/crm/ui/clients';
import { StepsProvider } from '@remberg/forms/common/main';
import { Address } from '@remberg/global/common/core';
import { PrefillingStepFunctions } from './interfaces';

@Injectable()
export class OrganizationPrefillingStepsProvider implements StepsProvider<PrefillingStepFunctions> {
  constructor(
    @Inject(ORGANIZATIONS_OFFLINE_SERVICE)
    private readonly organizationService: OrganizationOfflineServiceInterface,
    @Inject(CONTACTS_OFFLINE_SERVICE)
    private readonly contactService: ContactsOfflineServiceInterface,
  ) {}

  public getSteps(): Partial<PrefillingStepFunctions> {
    return {
      accountIdToAccount: (id: string | undefined) => this.getOrganizationById(id),
      accountToBillingAddressAddress,
      accountToCustomerNumber,
      userIdToUser: (id: string | undefined) => this.getContact(id),
      userToFullNameString,
    };
  }

  public async getOrganizationById(id: string | undefined): Promise<OrganizationRaw | undefined> {
    return id ? await this.organizationService.tryGetInstance(id) : undefined;
  }

  public async getContact(id: string | undefined): Promise<ContactBasic | undefined> {
    if (id) {
      const contact = await this.contactService.tryGetInstance(id);

      return contactRawToContactBasic(contact);
    }

    return undefined;
  }
}

function userToFullNameString(user: ContactBasic | undefined): string {
  return [user?.firstName, user?.lastName].filter(Boolean).join(' ');
}

function accountToBillingAddressAddress(
  organization: OrganizationRaw | undefined,
): Address | undefined {
  return organization?.crmData.shippingAddress;
}

function accountToCustomerNumber(organization: OrganizationRaw | undefined): string | undefined {
  return organization?.crmData.externalReference;
}
