<div [ngClass]="{ 'display-inline': showInline, 'phone-number-show-in-forms': !showInline }">
  <div class="label" *ngIf="!isLabelHidden">
    {{ label }}<span class="required" *ngIf="required">*</span>
  </div>
  <span *ngIf="showInline" class="fill-remaining-space"></span>
  <div class="value" [mdePopoverTriggerFor]="appPhoneNumberDisplayPopover">
    <ng-container *ngIf="value">
      <div *ngIf="showIcon" class="icon-wrapper">
        <mat-icon class="smaller-icon">phone</mat-icon>
      </div>
      <a
        data-test-id="phone-number-link"
        class="phone-number"
        [class]="href && !disableNavigation ? 'link' : 'no-link'"
        [href]="!disableNavigation ? href : ''"
      >
        {{ value | phoneNumber }}</a
      >
    </ng-container>
    <ng-container *ngIf="!value">
      <span>'-'</span>
    </ng-container>
  </div>

  <mde-popover
    #appPhoneNumberDisplayPopover="mdePopover"
    [mdePopoverTriggerOn]="isTooltipVisible && !disableNavigation ? 'hover' : 'none'"
    [mdePopoverOverlapTrigger]="false"
    [mdePopoverEnterDelay]="POPOVER_DELAY"
    [mdePopoverLeaveDelay]="POPOVER_DELAY"
  >
    <mat-card appearance="outlined">
      <span *ngIf="value" data-test-id="phone-number-value">{{ value | phoneNumber }}</span>
      <ng-container *ngIf="!value">
        <span>'-'</span>
      </ng-container>

      <a class="copy-to-clipboard" (click)="copyPhoneNumber()"><mat-icon>copy</mat-icon></a>

      <a
        class="open-in-new"
        *ngIf="href && !disableNavigation"
        [href]="!disableNavigation ? href : ''"
        ><mat-icon>open_in_new</mat-icon>
      </a>
    </mat-card>
  </mde-popover>
</div>
