import {
  ActivityPopulatableFieldType,
  ActivityRelatedObjectType,
  TypeForActivityFieldType,
} from '../definitions';
import { ActivityFieldTypeEnum } from '../enums';

export type ActivityArrayFieldTypeToPopulate = {
  [K in ActivityPopulatableFieldType]: TypeForActivityFieldType<K> extends (infer U)[] ? K : never;
}[ActivityPopulatableFieldType];

export const ACTIVITY_ARRAY_TYPE_TO_POPULATE_TYPE_MAP: Record<
  ActivityArrayFieldTypeToPopulate,
  ActivityRelatedObjectType
> = {
  [ActivityFieldTypeEnum.ASSET_ARRAY]: ActivityFieldTypeEnum.ASSET,
  [ActivityFieldTypeEnum.CONTACT_ARRAY]: ActivityFieldTypeEnum.CONTACT,
  [ActivityFieldTypeEnum.PLATFORM_FILE_ARRAY]: ActivityFieldTypeEnum.PLATFORM_FILE,
  [ActivityFieldTypeEnum.ORGANIZATION_ARRAY]: ActivityFieldTypeEnum.ORGANIZATION,
  [ActivityFieldTypeEnum.USER_GROUP_ARRAY]: ActivityFieldTypeEnum.USER_GROUP,
  [ActivityFieldTypeEnum.TICKET2_ARRAY]: ActivityFieldTypeEnum.TICKET2,
  [ActivityFieldTypeEnum.PART_ARRAY]: ActivityFieldTypeEnum.PART,
};
