export enum FileSourceTypeEnum {
  ASSET = 'product',
  ASSET_IMAGE = 'assetImage',
  ASSET_TYPE_IMAGE = 'productTypeImage',
  TICKET = 'ticket',
  OTHER = 'other',
  LOGO = 'logo',
  QR_CODE_LOGO = 'qrCodeLogo',
  SERVICE_REPORT = 'serviceReport',
  //TODO: check usage on the data level as there are no code references atm
  SR_SIGNATURE = 'serviceReportSignature',
  USER_PROFILE_IMAGE = 'userProfileImage',
  FORM_DATA = 'formData',
  ORGANIZATION_FAVICON = 'organizationFavicon',
  FORM_PDF_HEADER_FOOTER = 'formPDFHeaderFooter',
  EXPORT_FILE = 'exportFile',
  WORK_ORDER = 'workOrder',
  WORK_ORDER_DESCRIPTION = 'workOrderDescription',
  FORM = 'formAttachedFile',
  MAINTENANCE_PLAN = 'maintenancePlan',
  MAINTENANCE_PLAN_DESCRIPTION = 'maintenancePlanDescription',
  MAINTENANCE_PLAN_WO_DESCRIPTION = 'maintenancePlanWODescription',
  /**
   * **ATTENTION:** Use RICH_TEXT_FILE only for the Forms feature
   */
  RICH_TEXT_FILE = 'richTextFile',
  RICH_TEXT_WORK_ORDER_FILE = 'richTextWorkOrderFile',
  RICH_TEXT_CASE_FILE = 'richTextCaseFile',
  RICH_TEXT_MAINTENANCE_PLAN_WORK_ORDER_DESCRIPTION_FILE = 'richTextMaintenancePlanWorkOrderDescriptionFile',
  IMPORT_FILE = 'importFile',
  CASE_EXPORT = 'caseExport',
  EMAIL_ATTACHMENT = 'emailAttachment',
  PART_IMAGE = 'partImage',
  NOTE_ATTACHMENT = 'noteAttachment',
  NOTE_EMBEDDED_IMAGE = 'noteEmbeddedImage',
  EDITOR_TEMPORARY = 'editorTemporary',
  EMAIL_SIGNATURE_EMBEDDED_IMAGE = 'emailSignatureEmbeddedImage',
  MESSAGE_TEMPLATE_EMBEDDED_IMAGE = 'messageTemplateEmbeddedImage',
}
