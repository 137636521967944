<mat-card
  appearance="outlined"
  class="center"
  ngClass.lt-sm="landing-card"
  data-test-id="access-popup"
>
  <mat-card-header class="flex-row justify-center">
    <mat-card-title *ngIf="isRegistrationOnly">
      <span i18n="@@requestAccessTo">Request access to</span>
      <span class="domain-value no-bold">{{ workspaceName }}</span>
    </mat-card-title>
    <mat-card-title *ngIf="!isRegistrationOnly">
      <span i18n="@@loginOrRequestAccess">Login or request access</span>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content *ngIf="isRegistrationOnly">
    <div class="flex-col">
      <form class="full-width" [formGroup]="accessRequestFormGroup">
        <mat-form-field class="my-mat-form-field my-mat-form-field-full-width" appearance="outline">
          <mat-label i18n="@@email">Email</mat-label>
          <input
            formControlName="email"
            matInput
            placeholder="Email"
            i18n-placeholder="@@email"
            name="email"
            required
            data-test-id="new-request-email"
          />
          <mat-error
            *ngIf="accessRequestFormGroup.get('email')?.errors?.['pattern']"
            i18n="@@emailMustBeValid"
          >
            Email must be valid
          </mat-error>
        </mat-form-field>

        <mat-form-field class="my-mat-form-field my-mat-form-field-full-width" appearance="outline">
          <mat-label i18n="@@firstName">First Name</mat-label>
          <input formControlName="firstName" matInput name="firstName" required />
        </mat-form-field>

        <mat-form-field class="my-mat-form-field my-mat-form-field-full-width" appearance="outline">
          <mat-label i18n="@@lastName">Last Name</mat-label>
          <input formControlName="lastName" matInput name="lastName" required />
        </mat-form-field>

        <mat-form-field
          *ngIf="isContact"
          class="my-mat-form-field my-mat-form-field-full-width"
          appearance="outline"
        >
          <mat-label i18n="@@organizationyName">Organization Name</mat-label>
          <input formControlName="company" matInput name="company" required />
        </mat-form-field>

        <mat-form-field class="my-mat-form-field my-mat-form-field-full-width" appearance="outline">
          <mat-label i18n="@@jobPosition">Job Position</mat-label>
          <input formControlName="jobPosition" matInput name="jobPosition" [required]="isContact" />
        </mat-form-field>
      </form>

      <app-button-loader
        [buttonStyle]="'mat-flat-button'"
        (action)="onAccessRequestSubmitted()"
        [disabled]="accessRequestFormGroup.invalid"
        [materialColor]="'primary'"
        [loading]="isProcessingRequest"
        [text]="transtations.requestAccess"
        data-test-id="request-access-button"
      />

      <app-privacy-policy-text class="privacy-policy-text" />

      <mat-divider class="divider" />

      <div class="align-items-center">
        <span class="align-items-center clickable" (click)="onBackClicked()">
          <mat-icon class="x-small-icon">arrow_back</mat-icon>
          <a class="text-link" i18n="@@back"> Back </a>
        </span>
        <span class="fill-remaining-space"></span>
        <app-language-picker />
      </div>
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="!isRegistrationOnly">
    <div class="dialog-content">
      <div>
        <span i18n="@@youDoNotHaveAccessToThisAssetPleaseLoginOrRequestAccessBelow"
          >You do not have access to this asset. Please login or request access below.</span
        >
      </div>
      <button
        mat-flat-button
        color="primary"
        (click)="onLoginClicked()"
        i18n="@@login"
        data-test-id="login-button"
      >
        Login
      </button>

      <button
        mat-stroked-button
        color="primary"
        (click)="onRequestAccessClicked()"
        i18n="@@requestAccess"
        data-test-id="request-access-button"
      >
        Request Access
      </button>

      <mat-divider />
      <button
        mat-stroked-button
        i18n="@@cancel"
        class="details-btn"
        (click)="onClose()"
        data-test-id="access-popup-cancel"
      >
        Cancel
      </button>
    </div>
  </mat-card-content>
</mat-card>
