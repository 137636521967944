import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormTemplateService } from '@remberg/forms/ui/clients';
import { getIsoLanguageCodeForShortString } from '@remberg/global/common/core';
import { LogService } from '@remberg/global/ui';
import { filter, firstValueFrom } from 'rxjs';
import { RembergPage, RembergPageList, RembergRootPages } from '../helpers/rembergPage.model';
import { GlobalSelectors, RootGlobalState } from '../store';

@Injectable({
  providedIn: 'root',
})
export class RembergPageService {
  constructor(
    private store: Store<RootGlobalState>,
    private logger: LogService,
    private formTemplateService: FormTemplateService,
    @Inject(LOCALE_ID) protected locale: string,
  ) {}

  // TODO: store the remberg page list in the Global NgRx store once this is complete.
  // This ensures that the backend isn't called too often if it is not needed.
  public async prepareRembergPageList(url?: string): Promise<RembergPage[]> {
    await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsGlobalInitializationComplete).pipe(filter(Boolean)),
    );

    const rootPagesList: RembergPage[] = [];
    const isTenantOwner = await firstValueFrom(
      this.store.select(GlobalSelectors.selectIsTenantOwner),
    );
    for (const [pageListKey, element] of Object.entries(RembergPageList)) {
      // Skip elements that cannot match the given url
      if (url && !url.startsWith(element.link)) {
        continue;
      }

      const accessibleFeatures = [];

      for (const feature of element.requiredFeatures ?? []) {
        const hasFeature = await firstValueFrom(
          this.store.select(GlobalSelectors.selectHasFeature(feature)),
        );
        const hasPermission = await firstValueFrom(
          this.store.select(GlobalSelectors.selectHasPermission(feature, true)),
        );
        if (hasFeature && hasPermission) {
          accessibleFeatures.push(feature);
        }
      }

      if (
        accessibleFeatures?.length === element.requiredFeatures?.length &&
        (isTenantOwner || !element.onlyOEM)
      ) {
        rootPagesList.push(element);

        if (element.dynamicMenuAvailable && pageListKey === RembergRootPages.FORMS) {
          // Get list of forms available

          const tenantId = await firstValueFrom(this.store.select(GlobalSelectors.selectTenantId));
          const formTemplates = await firstValueFrom(
            this.formTemplateService.getFormTemplatesByTenant({ tenantId }),
          );
          if (formTemplates.data?.length > 0) {
            for (const formTemplateRecord of formTemplates.data) {
              const label =
                formTemplateRecord?.name?.[getIsoLanguageCodeForShortString(this.locale)];
              if (label) {
                rootPagesList.push({
                  label: label,
                  link: element.link + '/' + formTemplateRecord._id,
                  availableMobile: element.availableMobile,
                  requiredFeatures: element.requiredFeatures,
                });
              }
            }
          }
        }
      }
    }

    this.logger.debug()(`Page services prepared with ${rootPagesList.length} pages.`);
    return rootPagesList;
  }
}
