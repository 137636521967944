<div class="header-wrapper">
  <mat-icon class="mat-icon icon-primary">delete</mat-icon>
</div>

<h2 class="header-text" [innerHtml]="headerText | safeHtml"></h2>

<p class="content-text" [innerHtml]="paragraph1Text | safeHtml"></p>
<p class="content-text hint" [innerHtml]="paragraph2Text | safeHtml"></p>

<mat-form-field appearance="outline" class="my-mat-form-field my-mat-form-field-outline">
  <mat-label>{{ translations.textFieldLabel }}</mat-label>
  <input
    *ngIf="confirmationControl"
    [formControl]="confirmationControl"
    matInput
    type="text"
    placeholder="{{ placeholderText }}"
    cdkFocusInitial
    data-test-id="dialog-input"
  />
  <mat-error *ngIf="confirmationControl?.errors?.['required']">
    {{ translations.errorRequired }}
  </mat-error>
  <mat-error *ngIf="confirmationControl?.errors?.['isMatching']">
    {{ translations.errorIsMatching }}
  </mat-error>
</mat-form-field>

<div class="buttons-container">
  <button mat-raised-button [mat-dialog-close]="false" i18n="@@cancel">Cancel</button>
  <button
    class="button-confirm"
    color="primary"
    mat-raised-button
    [mat-dialog-close]="true"
    [disabled]="confirmationControl?.invalid"
    i18n="@@delete"
    data-test-id="delete-button"
  >
    Delete
  </button>
</div>
