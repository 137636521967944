import { ContactCompatibility } from '@remberg/crm/common/main';
import { NotificationTargetTypeEnum, NotificationTypeEnum } from '../enums';

export enum NotificationSettingsEnum {
  Email = 'email',
  Push = 'push',
  SMS = 'sms',
}

export interface Notification {
  _id?: string;
  deleted?: boolean;
  notificationType: NotificationTypeEnum;
  timeStamp: Date;
  message?: string;
  customer?: boolean;
  target?: string;
  targetId?: string;
  targetDescriptor?: string;
  targetSubId?: string;
  payload?: any;
  asset?: { _id: string } | string;
  usageInfo?: string;
  notifyingContact?: ContactCompatibility;
  targetContactIds?: ContactCompatibility[] | string[];
  suppressEmail?: ContactCompatibility[] | string[];
  tenantId: string;
  isRead?: boolean;

  // Frontend only
  receivedTimeStamp?: Date;
  headline?: string;
  targetType?: NotificationTargetTypeEnum;
}

export interface NotificationQueue {
  contact: ContactCompatibility;
  settings: NotificationSettings;
  message: string;
  target?: string;
  usageInfo?: string;
  targetContactIds?: ContactCompatibility[] | string[];
  tenantId: string;
}

export interface NotificationQueueMessage {
  type: string;
  notifications: Notification[];
  notificationIds?: string[];
  shouldDeleteAll?: boolean;
}

export interface NotificationSettingsChannels {
  push: boolean;
  email: boolean;
  sms: boolean;
}

export interface NotificationSettings {
  enabled: boolean;
  channels: NotificationSettingsChannels;

  account?: {
    channels: NotificationSettingsChannels;
    requestAccess?: { channels: NotificationSettingsChannels };
  };

  asset?: {
    channels: NotificationSettingsChannels;
    assignPerson?: { channels: NotificationSettingsChannels };
    documentRemoved?: { channels: NotificationSettingsChannels };
    update?: { channels: NotificationSettingsChannels };
  };

  assetType?: {
    channels: NotificationSettingsChannels;
    documentAdded: { channels: NotificationSettingsChannels };
    documentRemoved: { channels: NotificationSettingsChannels };
  };

  task?: {
    channels: NotificationSettingsChannels;
    update?: { channels: NotificationSettingsChannels };
    assignment?: { channels: NotificationSettingsChannels };
    overdue?: { channels: NotificationSettingsChannels };
  };

  ticket?: {
    channels: NotificationSettingsChannels;
    change?: { channels: NotificationSettingsChannels };
    created?: { channels: NotificationSettingsChannels };
  };

  export?: {
    channels: NotificationSettingsChannels;
    ready?: { channels: NotificationSettingsChannels };
  };

  formData?: {
    channels: NotificationSettingsChannels;
    mention?: { channels: NotificationSettingsChannels };
  };

  workOrder?: {
    channels: NotificationSettingsChannels;
    assignment: { channels: NotificationSettingsChannels };
    mention: { channels: NotificationSettingsChannels };
    overDue: { channels: NotificationSettingsChannels };
    schedulingChange: { channels: NotificationSettingsChannels };
  };
  workOrder2?: {
    channels: NotificationSettingsChannels;
    update: { channels: NotificationSettingsChannels };
    assignment: { channels: NotificationSettingsChannels };
    mention: { channels: NotificationSettingsChannels };
    overDue: { channels: NotificationSettingsChannels };
    dueDateApproaching: { channels: NotificationSettingsChannels };
    planningUpdate: { channels: NotificationSettingsChannels };
  };

  appointment?: {
    channels: NotificationSettingsChannels;
    assignment: { channels: NotificationSettingsChannels };
    update?: { channels: NotificationSettingsChannels };
  };

  maintenancePlan?: {
    channels: NotificationSettingsChannels;
    mention: { channels: NotificationSettingsChannels };
  };
}
