<!-- Main Button -->
<app-button-loader
  *ngIf="
    buttonList &&
    buttonList[0] &&
    (!buttonList[0].rightsCheck$ || (buttonList[0].rightsCheck$() | async))
  "
  class="dropdown-loader-button"
  [ngClass]="{ 'full-width': isFullWidth, 'dropdown-button-large': isButtonSizeLarge }"
  [disabled]="disabled"
  [loading]="loading"
  [buttonStyle]="buttonStyle"
  [text]="buttonList[0].label"
  [dataTestId]="dataTestId ?? buttonList[0].name ?? buttonList[0].dataTestId"
  [additionalClass]="additionalClass + ((buttonList?.length ?? 0) > 1 ? ' dropdown-arrow' : ' ')"
  [additionalClassIsSmallView]="
    additionalClassIsSmallView + ((buttonList?.length ?? 0) > 1 ? ' dropdown-arrow' : ' ')
  "
  [materialColor]="materialColor"
  [isErrorState]="highlightAsRequired"
  [overrideNgStyle]="getAdditionalButtonStyles"
  [icon]="buttonList[0].hasIcon"
  [spinnerSize]="buttonLoaderSpinnerSize"
  (action)="clicked(0)"
>
</app-button-loader>

<!-- Optional: Dropdown Button & Trigger -->
<!-- Very bad solution, check dynamic switching progress here /https://github.com/angular/components/issues/15367 -->
<ng-container
  *ngIf="
    buttonList &&
    buttonList.length > 1 &&
    (!buttonList[0].rightsCheck$ || (buttonList[0].rightsCheck$() | async))
  "
  [ngSwitch]="buttonStyle"
>
  <button
    *ngSwitchCaseDefault
    mat-button
    [ngClass]="[
      'dropdown-arrow-button',
      isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
      highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
    ]"
    [color]="materialColor"
    [disabled]="disabled || loading"
    [matMenuTriggerFor]="menu"
    [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
  >
    <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
  </button>
  <button
    *ngSwitchCase="'mat-raised-button'"
    mat-raised-button
    [ngClass]="[
      'dropdown-arrow-button',
      isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
      highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
    ]"
    [color]="materialColor"
    [disabled]="disabled || loading"
    [matMenuTriggerFor]="menu"
    [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
  >
    <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
  </button>
  <button
    *ngSwitchCase="'mat-stroked-button'"
    mat-stroked-button
    [ngClass]="[
      'dropdown-arrow-button',
      isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
      highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
    ]"
    [color]="materialColor"
    [disabled]="disabled || loading"
    [matMenuTriggerFor]="menu"
    [style.border-style]="borderStyle"
    [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
  >
    <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
  </button>
  <button
    *ngSwitchCase="'mat-flat-button'"
    mat-flat-button
    [ngClass]="[
      'dropdown-arrow-button',
      isButtonSizeLarge ? 'dropdown-arrow-button-large' : '',
      highlightAsRequired ? 'dropdown-arrow-button-warn' : '',
    ]"
    [color]="materialColor"
    [disabled]="disabled || loading"
    [matMenuTriggerFor]="menu"
    [attr.data-test-id]="dataTestIdForDropDown ?? 'dropdown-button'"
  >
    <mat-icon class="down-icon">keyboard_arrow_down</mat-icon>
  </button>
</ng-container>

<!-- Dropdown Menu -->
<mat-menu #menu="matMenu" xPosition="before" [backdropClass]="backdropClass">
  <ng-container *ngFor="let button of buttonList?.slice(1); let i = index">
    <ng-container *ngIf="!button.rightsCheck$ || (button.rightsCheck$() | async)">
      <button
        mat-menu-item
        (click)="clicked(i + 1)"
        [attr.data-test-id]="button.dataTestId ?? button.name"
        [ngClass]="button.hasClass"
      >
        <div *ngIf="button.hasColorPreface" [ngClass]="button.hasColorPreface">&nbsp;</div>
        <mat-icon *ngIf="button.hasIcon">{{ button.hasIcon }}</mat-icon>
        <span class="text-ellipsis">{{ button.label }}</span>
      </button>
    </ng-container>
  </ng-container>
</mat-menu>
