import { NavlinkTypesEnum } from './enums';
import { MainRoutes } from './main-routes';

export interface NavLink {
  label: string | NavlinkTypesEnum;
  link: string;
  icon: string;
  children?: NavLink[];
  expanded?: boolean;
  highlighted?: boolean;
  flipped?: boolean;
  rotateRight?: boolean;
  rotateLeft?: boolean;
  svg?: boolean;
  newTab?: boolean;
  dataTestId?: string | null;
  /* Temporal config for compatibility with both nav link design (with/without view types) see #6161 */
  hideIfOpen?: boolean;
  filled?: boolean;
}

export const FILLER_NAVLINK = {
  label: NavlinkTypesEnum.FILLER,
  link: '',
  icon: '',
  children: [],
  expanded: false,
  highlighted: false,
};
export const DIVIDER_NAVLINK = {
  label: NavlinkTypesEnum.DIVIDER,
  link: '',
  icon: '',
  children: [],
};

export const ASSETS_NAVLINK = {
  label: $localize`:@@assets:Assets`,
  link: `/${MainRoutes.ASSETS}`,
  icon: 'asset',
  svg: true,
  children: [],
  dataTestId: 'assets-menu-item',
};

export const ASSETS_CHILD_NAVLINK = {
  label: $localize`:@@assets:Assets`,
  link: `/${MainRoutes.ASSETS}`,
  icon: 'asset',
  svg: true,
  dataTestId: 'assets-child-menu-item',
  hideIfOpen: true,
  filled: true,
};

export const ASSETS_TYPES_NAVLINK = {
  label: $localize`:@@assetTypes:AssetTypes`,
  link: `/${MainRoutes.ASSETS}/types`,
  icon: 'event',
  dataTestId: 'asset-types-menu-item',
  filled: true,
};

export const CASES_NAVLINK = {
  label: $localize`:@@serviceCases:Cases`,
  link: `/${MainRoutes.SERVICE_CASES}`,
  icon: 'forum',
  children: [
    {
      label: $localize`:@@serviceCases:Cases`,
      link: `/${MainRoutes.SERVICE_CASES}`,
      icon: 'forum',
      children: [],
      dataTestId: 'cases-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'cases-menu-item',
};

export const TICKETS2_NAVLINK = {
  label: $localize`:@@tickets2:Tickets 2`,
  link: `/${MainRoutes.TICKETS2}`,
  icon: 'forum',
  children: [
    {
      label: $localize`:@@tickets2:Tickets 2`,
      link: `/${MainRoutes.TICKETS2}`,
      icon: 'forum',
      dataTestId: 'tickets2-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'tickets2-menu-item',
};

export const DASHBOARDS_NAVLINK = {
  label: $localize`:@@dashboard:Dashboard`,
  link: `/${MainRoutes.DASHBOARD}`,
  icon: 'leaderboard',
  children: [
    {
      label: $localize`:@@dashboard:Dashboard`,
      link: `/${MainRoutes.DASHBOARD}`,
      icon: 'leaderboard',
      children: [],
      dataTestId: 'dashboard-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'dashboard-menu-item',
};

export const GLOBAL_MAP_NAVLINK = {
  label: $localize`:@@map:Map`,
  link: `/${MainRoutes.MAP}`,
  icon: 'map',
  children: [
    {
      label: $localize`:@@map:Map`,
      link: `/${MainRoutes.MAP}`,
      icon: 'map',
      children: [],
      dataTestId: 'map-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'map-menu-item',
};

export const COMPANIES_NAVLINK = {
  label: $localize`:@@organizations:Organizations`,
  link: `/${MainRoutes.ORGANIZATIONS}`,
  icon: 'business',
  children: [
    {
      label: $localize`:@@organizations:Organizations`,
      link: `/${MainRoutes.ORGANIZATIONS}`,
      icon: 'business',
      children: [],
      dataTestId: 'organizations-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'organizations-menu-item',
};

export const CONTACTS_NAVLINK = {
  label: $localize`:@@contacts:Contacts`,
  link: `/${MainRoutes.CONTACTS}`,
  icon: 'person',
  children: [
    {
      label: $localize`:@@contacts:Contacts`,
      link: `/${MainRoutes.CONTACTS}`,
      icon: 'person',
      children: [],
      dataTestId: 'contacts-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'contacts-menu-item',
};

export const PARTS_NAVLINK = {
  label: $localize`:@@parts:Parts`,
  link: `/${MainRoutes.PARTS}`,
  icon: 'workspaces_outlined',
  children: [
    {
      label: $localize`:@@parts:Parts`,
      link: `/${MainRoutes.PARTS}`,
      icon: 'workspaces_outlined',
      children: [],
      dataTestId: 'parts-child-menu-item',
      hideIfOpen: true,
      filled: true,
    },
  ],
  dataTestId: 'parts-menu-item',
  filled: true,
};

export const FILES_NAVLINK = {
  label: $localize`:@@files:Files`,
  link: `/${MainRoutes.FILE_DRIVE}`,
  icon: 'folder',
  children: [
    {
      label: $localize`:@@files:Files`,
      link: `/${MainRoutes.FILE_DRIVE}`,
      icon: 'folder',
      children: [],
      dataTestId: 'files-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'files-menu-item',
};

export const TASKS_NAVLINK = {
  label: $localize`:@@tasks:Tasks`,
  link: `/${MainRoutes.TASKS}`,
  icon: 'check_circle',
  dataTestId: 'tasks-menu-item',
  children: [
    {
      label: $localize`:@@tasks:Tasks`,
      link: `/${MainRoutes.TASKS}`,
      icon: 'check_circle',
      children: [],
      dataTestId: 'tasks-child-menu-item',
      hideIfOpen: true,
    },
  ],
};

export const WORKORDERS_2_NAVLINK = {
  label: $localize`:@@workOrders:Work Orders`,
  link: `/${MainRoutes.WORK_ORDERS_2}`,
  icon: 'handyman',
  dataTestId: 'work-orders2-menu-item',
};

export const WORKORDERS_2_CHILD_NAVLINK = {
  ...WORKORDERS_2_NAVLINK,
  dataTestId: 'work-orders2-child-menu-item',
  icon: 'event',
  hideIfOpen: true,
  filled: true,
};

export const SCHEDULING_BOARD_2_NAVLINK = {
  label: $localize`:@@schedulingBoard:Scheduling Board`,
  link: `/${MainRoutes.WORK_ORDERS_2}/scheduling`,
  icon: 'people',
  children: [],
  expanded: false,
  highlighted: false,
  dataTestId: 'scheduling-board-2-menu-item',
};

export const MAINTENANCE_PLANS_NAVLINK = {
  label: $localize`:@@maintenancePlans:Maintenance Plans`,
  link: `/${MainRoutes.WORK_ORDERS_2}/plans`,
  icon: 'people',
  children: [],
  expanded: false,
  highlighted: false,
  dataTestId: 'maintenance-plans-menu-item',
};

export const MAINTENANCE_PLANS_2_NAVLINK = {
  label: $localize`:@@maintenancePlans2:Maintenance Plans 2`,
  link: `/${MainRoutes.WORK_ORDERS_2}/plans2`,
  icon: 'people',
  children: [],
  expanded: false,
  highlighted: false,
  dataTestId: 'maintenance-plans-2-menu-item',
};

export const FORMS_NAVLINK = {
  label: $localize`:@@forms:Forms`,
  link: `/${MainRoutes.FORMS}`,
  icon: 'content_paste',
  children: [
    {
      label: $localize`:@@forms:Forms`,
      link: `/${MainRoutes.FORMS}`,
      icon: 'content_paste',
      children: [],
      dataTestId: 'forms-child-menu-item',
      hideIfOpen: true,
    },
  ],
  dataTestId: 'forms-menu-item',
};

export const SETTINGS_LINK = {
  label: $localize`:@@settings:Settings`,
  link: `/${MainRoutes.SETTINGS}`,
  icon: 'settings',
  children: [
    {
      label: $localize`:@@settings:Settings`,
      link: `/${MainRoutes.SETTINGS}`,
      icon: 'settings',
      children: [],
      expanded: false,
      highlighted: false,
      dataTestId: 'settings-child-menu-item',
      hideIfOpen: true,
    },
  ],
  expanded: false,
  highlighted: false,
  dataTestId: 'settings-menu-item',
};

export const REMBERG_ADMIN_NAVLINKS = [
  {
    label: $localize`:@@overview:Overview`,
    link: '/remberg/admin',
    icon: 'asset',
    svg: true,
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'starting',
  },
  {
    label: $localize`:@@users:Users`,
    link: '/remberg/users',
    icon: 'people',
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'users',
  },
  {
    label: $localize`:@@tenants:Tenants`,
    link: '/remberg/tenants',
    icon: 'domain',
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'tenants',
  },
  {
    label: $localize`:@@emails:Emails`,
    link: '/remberg/emails',
    icon: 'email',
    children: [
      {
        label: $localize`:@@incoming:Incoming`,
        link: '/remberg/emails/incoming',
        icon: 'email',
        children: [],
        expanded: false,
        highlighted: false,
        dataTestId: 'emails-incoming',
      },
      {
        label: $localize`:@@outgoing:Outgoing`,
        link: '/remberg/emails/outgoing',
        icon: 'email',
        children: [],
        expanded: false,
        highlighted: false,
        dataTestId: 'emails-outgoing',
      },
    ],
    expanded: false,
    highlighted: false,
    dataTestId: 'emails',
  },
  {
    label: $localize`:@@qrcodes:QR Codes`,
    link: '/remberg/qrcodes',
    icon: 'insert_chart',
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'qrcodes',
  },
  {
    label: $localize`:@@Imports:Imports`,
    link: '/remberg/imports',
    icon: 'cloud_upload',
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'imports',
  },
  {
    label: $localize`:@@API:API`,
    link: '/remberg/api',
    icon: 'api',
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'api',
  },
  {
    label: $localize`:@@ingestionJobs:Ingestion Jobs`,
    link: '/remberg/ingestion-jobs',
    icon: 'auto_fix_high',
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'ingestion-jobs',
  },
  {
    label: $localize`:@@configuration:Configuration`,
    link: '/remberg/globalconfig',
    icon: 'settings',
    children: [],
    expanded: false,
    highlighted: false,
    dataTestId: 'settings',
  },
];
