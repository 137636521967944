import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdvancedFilterOperatorEnum } from '@remberg/advanced-filters/common/main';
import {
  DriveFile,
  DriveFilesAdvancedFilter,
  DriveFilesCreateFileBody,
  DriveFilesCreateFolderBody,
  DriveFilesFilterFieldEnum,
  DriveFilesFindManyQuery,
  DriveFilesFindManyResponse,
  DriveFilesFindOneQuery,
  DriveFilesGetSignedUrlQuery,
  DriveFilesMoveBody,
  DriveFilesUpdateBody,
  FileResponseTypeEnum,
} from '@remberg/files/common/main';
import { API_URL_PLACEHOLDER } from '@remberg/global/ui';
import { Observable } from 'rxjs';
import { ROOT_HOST_ID, ROOT_ID } from './drive-files.definitions';

@Injectable({
  providedIn: 'root',
})
export class DriveFilesService {
  private readonly driveFilesUrl = `${API_URL_PLACEHOLDER}/files/v2/drive`;

  constructor(private http: HttpClient) {}

  public createFolder(metadata: DriveFilesCreateFolderBody): Observable<DriveFile> {
    if (metadata.parentId === ROOT_ID) {
      delete metadata.parentId;
    }

    return this.http.post<DriveFile>(`${this.driveFilesUrl}/folder`, metadata);
  }

  public getFilesWithCount(
    options: DriveFilesFindManyQuery & { isFolder?: boolean },
  ): Observable<DriveFilesFindManyResponse> {
    let params = new HttpParams();
    params = this.setHttpParam('page', options.page, params);
    params = this.setHttpParam('limit', options.limit, params);
    params = this.setHttpParam('sortDirection', options.sortDirection, params);
    params = this.setHttpParam('sortField', options.sortField, params);
    params = this.setHttpParam('search', options.search, params);
    params = this.setHttpParam('filterObject', options.filterObject, params);
    params = this.setHttpParam('isAssetView', options.isAssetView, params);
    params = this.setHttpParam('ignoreFileIds', options.ignoreFileIds, params);
    if (options.folderId !== ROOT_ID && options.folderId !== ROOT_HOST_ID) {
      params = this.setHttpParam('folderId', options.folderId, params);
    }

    let parsedStaticFilters: DriveFilesAdvancedFilter[] = options.staticFilters ?? [];
    if (typeof options.isFolder === 'boolean') {
      const isFolderFilter: DriveFilesAdvancedFilter = {
        identifier: DriveFilesFilterFieldEnum.IS_FOLDER,
        operator: AdvancedFilterOperatorEnum.IS,
        value: options.isFolder.toString(),
      };
      parsedStaticFilters = [...parsedStaticFilters, isFolderFilter];
    }

    params = this.setHttpParam('staticFilters', parsedStaticFilters, params);

    return this.http.get<DriveFilesFindManyResponse>(`${this.driveFilesUrl}`, { params });
  }

  public getFilesByIds(fileIds: string[]): Observable<DriveFile[]> {
    return this.http.put<DriveFile[]>(`${this.driveFilesUrl}`, { fileIds });
  }

  public getFile(id: string, options: DriveFilesFindOneQuery): Observable<DriveFile> {
    let params = new HttpParams();

    if (options.assetId !== undefined) {
      params = params.set('assetId', String(options.assetId));
    }
    if (options.assetTypeId !== undefined) {
      params = params.set('assetTypeId', String(options.assetTypeId));
    }
    return this.http.get<DriveFile>(`${this.driveFilesUrl}/${id}`, { params });
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.driveFilesUrl}/${id}`);
  }

  public createFile(
    file: File,
    metadata: DriveFilesCreateFileBody,
  ): Observable<HttpEvent<DriveFile>> {
    const formData = new FormData();

    for (const [key, value] of Object.entries(metadata)) {
      if ((key === 'parentId' && value === ROOT_ID) || value == null) {
        continue;
      }
      if (key === 'assetIds' && value) {
        for (const assetId of value as string[]) {
          formData.append('assetIds', assetId);
        }
        continue;
      }
      if (key === 'assetTypeIds' && value) {
        for (const assetTypeId of value as string[]) {
          formData.append('assetTypeIds', assetTypeId);
        }
        continue;
      }
      formData.append(key, value);
    }

    formData.append('file', file, encodeURIComponent(file.name));
    return this.http.post<DriveFile>(this.driveFilesUrl, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public downloadFile(id: string): Observable<Blob> {
    return this.http.get(`${this.driveFilesUrl}/${id}/download`, {
      responseType: 'blob',
    });
  }
  public getFolderSignedUrl(id: string): Observable<string> {
    const params = new HttpParams();
    return this.http.get(`${this.driveFilesUrl}/${id}/foldersignedurl`, {
      params,
      reportProgress: true,
      responseType: 'text',
    });
  }

  public getSignedUrl(id: string, query: DriveFilesGetSignedUrlQuery): Observable<string> {
    const fileResponseType = query.fileResponseType ?? FileResponseTypeEnum.ATTACHMENT;
    let params = new HttpParams();
    params = params.set('fileResponseType', fileResponseType);
    return this.http.get(`${this.driveFilesUrl}/${id}/filesignedurl`, {
      params,
      responseType: 'text',
    });
  }

  public updateFile(id: string, updateFileDto: DriveFilesUpdateBody): Observable<DriveFile> {
    return this.http.patch<DriveFile>(`${this.driveFilesUrl}/${id}/`, updateFileDto);
  }

  public move(id: string, targetId: string): Observable<DriveFile> {
    const body: DriveFilesMoveBody = {};
    if (targetId !== ROOT_ID) {
      body.targetId = targetId;
    }
    return this.http.patch<DriveFile>(`${this.driveFilesUrl}/${id}/move`, body);
  }

  public getFilePopulated(id: string): Observable<DriveFile> {
    return this.http.get<DriveFile>(`${this.driveFilesUrl}/${id}/relationships`);
  }

  public unassignAsset(id: string, assetId: string): Observable<void> {
    return this.http.patch<void>(`${this.driveFilesUrl}/${id}/unassignasset/${assetId}`, null);
  }

  public getKnowledgeBasePageCount(tenantId: string): Observable<number> {
    const params = new HttpParams().set('tenantId', tenantId);
    return this.http.get<number>(`${this.driveFilesUrl}/knowledgebasepagecount`, { params });
  }

  private setHttpParam(key: string, value: unknown, params: HttpParams): HttpParams {
    if (!value && typeof value !== 'boolean') return params;
    switch (typeof value) {
      case 'boolean':
      case 'number':
      case 'string':
        return params.set(key, value);
      case 'object':
        return params.set(key, JSON.stringify(value));
      default:
        return params;
    }
  }
}
