import { isDefined, TableColumnData } from '@remberg/global/common/core';

export function parseColumnData<T extends string>(
  input: string | undefined,
): TableColumnData<T>[] | undefined {
  if (!input) {
    return undefined;
  }

  try {
    const data = JSON.parse(input);
    if (Array.isArray(data) && data.every((item) => isTableColumnData<T>(item))) {
      return data;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't parse column data`, e);
  }

  return undefined;
}

function isTableColumnData<T extends string>(object: unknown): object is TableColumnData<T> {
  return isDefined(object) && !!(object as TableColumnData<T>).identifier;
}
