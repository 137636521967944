import { FeatureFlagEnum, FilterType } from '@remberg/global/common/core';
import { MainRoutes } from '@remberg/global/ui';

interface ViewConfig {
  id: string;
  label: string;
  hideFilters: string[];
  link: string;
  icon: string;
  children: any[];
  expanded: boolean;
  highlighted: boolean;
  filters: FilterType<string>[];
  onlyOEM?: boolean;
}

export interface RembergPage {
  id?: string;
  label: string;
  link: string;
  icon?: string;
  sequence?: number;
  children?: { [key in string]: ViewConfig };
  requiredFeatures?: FeatureFlagEnum[];
  /** True if only the OEM can access this page. */
  onlyOEM?: boolean;
  availableMobile?: boolean;
  dynamicMenuAvailable?: boolean;
}

/** Remberg Root Pages for Web and Mobile app */
export enum RembergRootPages {
  DASHBOARD = 'Dashboard',
  ASSETS = 'Assets',
  FILES = 'Files',
  WORKORDERS_2 = 'WorkOrders2',
  SCHEDULING = 'Scheduling',
  MAINTENANCE_PLANS = 'MaintenancePlan',
  CASES = 'Cases',
  FORMS = 'Forms',
  PARTS = 'parts',
}

export const RembergPageList: { [key in RembergRootPages]: RembergPage } = {
  [RembergRootPages.ASSETS]: {
    label: $localize`:@@assets:Assets`,
    link: '/assets',
    requiredFeatures: [FeatureFlagEnum.ASSETS],
    availableMobile: false,
  },
  [RembergRootPages.FILES]: {
    label: $localize`:@@files:Files`,
    link: `/${MainRoutes.FILE_DRIVE}`,
    requiredFeatures: [FeatureFlagEnum.FILES],
    availableMobile: false,
    onlyOEM: true,
  },
  [RembergRootPages.CASES]: {
    label: $localize`:@@serviceCases:Cases`,
    link: '/servicecases',
    requiredFeatures: [FeatureFlagEnum.CASES],
    availableMobile: false,
  },
  [RembergRootPages.WORKORDERS_2]: {
    label: $localize`:@@workOrders:Work Orders`,
    link: '/workorders2',
    requiredFeatures: [FeatureFlagEnum.WORK_ORDERS_TEMPORARY],
    availableMobile: true,
    onlyOEM: true,
  },
  [RembergRootPages.SCHEDULING]: {
    label: $localize`:@@schedulingBoard:Scheduling Board`,
    link: '/workorders2/scheduling',
    requiredFeatures: [
      FeatureFlagEnum.WORK_ORDERS_TEMPORARY,
      FeatureFlagEnum.WORKORDERS_SCHEDULING,
    ],
    availableMobile: false,
    onlyOEM: true,
  },
  [RembergRootPages.MAINTENANCE_PLANS]: {
    label: $localize`:@@maintenancePlans:Maintenance Plans`,
    link: '/workorders2/plans',
    requiredFeatures: [
      FeatureFlagEnum.WORK_ORDERS_TEMPORARY,
      FeatureFlagEnum.WORKORDERS_MAINTENANCEPLANS,
    ],
    availableMobile: false,
    onlyOEM: true,
  },
  [RembergRootPages.FORMS]: {
    label: $localize`:@@forms:Forms`,
    link: '/forms',
    requiredFeatures: [FeatureFlagEnum.FORMS],
    availableMobile: true,
    dynamicMenuAvailable: true,
    onlyOEM: true,
  },
  [RembergRootPages.PARTS]: {
    label: $localize`:@@parts:Parts`,
    link: '/parts',
    requiredFeatures: [FeatureFlagEnum.PARTS],
    availableMobile: false,
    onlyOEM: true,
  },
  [RembergRootPages.DASHBOARD]: {
    label: $localize`:@@dashboard:Dashboard`,
    link: '/dashboard',
    requiredFeatures: [FeatureFlagEnum.DASHBOARDS],
    availableMobile: false,
    onlyOEM: true,
  },
};
