import { AssetBasic } from '@remberg/assets/common/base';
import { CustomPropertyValue } from '@remberg/custom-properties/common/main';
import {
  Address,
  CreationTypeEnum,
  RembergTimestamp,
  createMapper,
} from '@remberg/global/common/core';
import { AssetStatusEnum } from '../definitions';

export interface AssetRaw {
  // basic properties
  _id: string;
  tenantId: string;
  createdAt: RembergTimestamp;
  updatedAt: RembergTimestamp;
  createdById?: string;
  updatedById?: string;
  createdByType: CreationTypeEnum;
  isDeleted: boolean;

  // specific properties

  assetTypeId: string;
  assetTypeLabel: string;
  assetTypeImageId?: string;
  assetImageId?: string;
  parentId?: string;
  ancestorIds: string[];
  serialNumber: string;
  status: AssetStatusEnum;
  relatedOrganizationIds: string[];
  tenantOwnerContactId?: string;
  relatedContactIds: string[];
  relatedQRCodeIds: string[];
  location?: Address;
  userGroupIds: string[];
  relatedPartIds: string[];
  customPropertyValues: CustomPropertyValue<false>[];
}

function mapOneAssetRawToAssetBasic(asset: AssetRaw): AssetBasic {
  return {
    _id: asset._id,
    serialNumber: asset.serialNumber,
    assetTypeId: asset.assetTypeId,
    assetTypeName: asset.assetTypeLabel,
    assetTypeImageId: asset.assetTypeImageId,
    customerId: asset.relatedOrganizationIds[0],
    assetImageId: asset.assetImageId,
  };
}

export const mapAssetRawToAssetBasic = createMapper(mapOneAssetRawToAssetBasic);
