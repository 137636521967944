import { Pipe, PipeTransform } from '@angular/core';
import { IsoLanguageCodesEnum } from '@remberg/global/common/core';

export const ISO_LANGUAGE_CODES_ENUM_TRANSLATIONS: Record<IsoLanguageCodesEnum, string> = {
  [IsoLanguageCodesEnum.EN]: $localize`:@@english:English`,
  [IsoLanguageCodesEnum.DE]: $localize`:@@german:German`,
  [IsoLanguageCodesEnum.TR]: $localize`:@@turkish:Turkish`,
  [IsoLanguageCodesEnum.FR]: $localize`:@@french:French`,
  [IsoLanguageCodesEnum.IT]: $localize`:@@italian:Italian`,
  [IsoLanguageCodesEnum.ES]: $localize`:@@spanish:Spanish`,
  [IsoLanguageCodesEnum.EL]: $localize`:@@greek:Greek`,
  [IsoLanguageCodesEnum.TH]: $localize`:@@thai:Thai`,
  [IsoLanguageCodesEnum.PL]: $localize`:@@polish:Polish`,
  [IsoLanguageCodesEnum.CS]: $localize`:@@czech:Czech`,
};

@Pipe({
  name: 'translateIsoLanguageCodesEnum',
  standalone: true,
})
export class TranslateIsoLanguageCodesEnumPipe implements PipeTransform {
  public transform(value?: IsoLanguageCodesEnum): string {
    return value ? ISO_LANGUAGE_CODES_ENUM_TRANSLATIONS[value] : '';
  }
}
