import { AssetBasic } from '@remberg/assets/common/base';
import { Asset } from '../../assets';

export function mapAssetToAssetBasic(asset: Asset): AssetBasic;
export function mapAssetToAssetBasic(asset: Asset | undefined): AssetBasic | undefined {
  return asset
    ? {
        _id: asset._id,
        serialNumber: asset.serialNumber,
        assetTypeId: asset.assetTypeId,
        assetTypeName: asset.assetTypeLabel,
        assetTypeImageId: asset.assetTypeImageId,
        customerId: asset.relatedOrganizationIds[0],
        assetImageId: asset.assetImageId,
      }
    : undefined;
}
