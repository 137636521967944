/** [MDN Reference](https://developer.mozilla.org/docs/Web/API/MouseEvent/button) */
enum MOUSE_BUTTONS {
  // Main button pressed, usually the left button or the un-initialized state
  MAIN = 0,
  // Auxiliary button pressed, usually the wheel button or the middle button (if present)
  AUXILIARY = 1,
  // Secondary button pressed, usually the right button
  SECONDARY = 2,
  // Fourth button, typically the Browser Back button
  BROWSER_BACK = 3,
  // Fifth button, typically the Browser Forward button
  BROWSER_FORWARD = 4,
}

export function shouldOpenInNewTab(event: MouseEvent): boolean {
  const isLeftMouseClick = event.type === 'click' && event.button === MOUSE_BUTTONS.MAIN;
  const isAuxiliaryMouseButtonUp =
    event.type === 'mouseup' && event.button === MOUSE_BUTTONS.AUXILIARY;
  const isControlKeyPressed = event.ctrlKey || event.metaKey || event.shiftKey;

  return (isLeftMouseClick && isControlKeyPressed) || isAuxiliaryMouseButtonUp;
}
