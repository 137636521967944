import { HttpErrorResponse } from '@angular/common/http';
import { isErrorLike } from '@remberg/global/common/core';

export function getErrorMessage(error: unknown): string {
  if (error instanceof HttpErrorResponse) {
    if (error.status === 0) {
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/status
      //  "Before the request completes, the value of status is 0.
      //   Browsers also report a status of 0 in case of XMLHttpRequest errors."
      // i.e it means that the request couldn't be completed at all.
      // The reasons for that:
      //  - network connection loss,
      //  - blocked by browser extensions,
      //  - illegal cross origin request,
      //  - the request itself was cancelled in code
      // https://stackoverflow.com/a/14507670

      const request: XMLHttpRequest | undefined =
        error.error instanceof ProgressEvent && error.error.target instanceof XMLHttpRequest
          ? error.error.target
          : undefined;

      // request was aborted
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/abort
      if (request?.readyState === XMLHttpRequest.UNSENT) {
        return $localize`:@@requestToXWasAborted:Request to ${error.url} was aborted`;
      }

      return $localize`:@@requestToXCouldntBeDone:Request to ${error.url} couldn't be done. Please check your internet connection.`;
    }

    const requestId = error.headers.get('remberg-request-id');
    return requestId ? `${error.message} rId: ${requestId}` : error.message;
  }

  if (isErrorLike(error)) {
    return error.message;
  }

  return (error ?? '').toString();
}
