import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum } from '@remberg/global/common/core';
import { FormRightsEnum, LogService } from '@remberg/global/ui';
import { firstValueFrom } from 'rxjs';
import { GlobalSelectors } from '../../store';

export const formsGuardFn: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
): Promise<boolean> => {
  const router = inject(Router);
  const logger = inject(LogService);
  const store = inject(Store);

  const lastURLPart = route.url[route.url.length - 1] ? route.url[route.url.length - 1].path : '';

  const hasFeature = await firstValueFrom(
    store.select(GlobalSelectors.selectHasFeature(FeatureFlagEnum.FORMS)),
  );

  const hasFormsFillPermission = await firstValueFrom(
    store.select(GlobalSelectors.selectHasPermission(FormRightsEnum.FORMS_FILL)),
  );

  if (hasFeature && hasFormsFillPermission && lastURLPart === 'detail') return true;

  const hasPermission = await firstValueFrom(
    store.select(GlobalSelectors.selectHasPermission(FormRightsEnum.FORMS_ENABLED)),
  );

  if (hasFeature && hasPermission) return true;

  logger.info()('Forms Guard: Redirecting from: ' + router.url);
  void router.navigate(['/']);
  return false;
};
